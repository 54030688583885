import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class AddAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAccountInProgress: false,
      addAccountErrorText: null,
      accountNameValid: false,
      accountBalanceValid: true,
      accountLastFourValid: true
    };
  }

  validateAddAccountForm() {
    var balance, lastFour, name;

    if (!!this.name.value && this.name.value.length > 0
          && this.name.value.length <= 20) {
      name = true;
    } else {
      name = false;
    }
    if (!!this.balance.value && this.balance.value
        .match(/^[0-9]*(\.[0-9]{0,2})?$/)
      && this.balance.value >= 0 && this.balance.value.length <= 16) {
      balance = true;
    } else {
      balance = false;
    }
    if (!this.lastFour.value || this.lastFour.value.match(/^[0-9]{4}$/)) {
      lastFour = true;
    } else {
      lastFour = false;
    }

    this.setState({
      accountNameValid: name,
      accountBalanceValid: balance,
      accountLastFourValid: lastFour
    });
  }

  addAccount(e) {
    e.preventDefault();
    this.setState({
      addAccountInProgress: true
    }, () => {
      $.ajax({
        method: 'POST',
        url: API_ROUTE + '/account/',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        data: JSON.stringify({
          bankId: this.props.bank.id,
          name: this.name.value,
          balance: this.balance.value,
          lastFourDigits: this.lastFour.value
        })
      }).done((data) => {
          this.props.getAccounts();
          this.props.history.push('/');
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          addAccountErrorText: errorString,
          addAccountInProgress: false
        });
      });
    });
  }

  render() {
    return (
      <div>
        <h3>
          Fill in the information and click 'Add account' to add the account to
          {' '}the '{this.props.bank.name}' bank
        </h3>
        <div
          className='loginFormWrapper'>
          <Form
            className='loginForm'
            fluid='true'>
            <FormGroup
              controlId="loginError"
              validationState='error'
              hidden={!this.state.addAccountErrorText}>
              <ControlLabel>{this.state.addAccountErrorText}</ControlLabel>
            </FormGroup>
            <FormGroup
              controlId="formName"
              validationState={this.state.accountNameValid
                ? 'success'
                : 'error'}>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                bsSize='large'
                onChange={() => this.validateAddAccountForm()}
                disabled={this.state.addAccountInProgress}
                autoComplete='off'
                inputRef={(ref) => {this.name = ref}}
                type='text'
                placeholder='Name'/>
            </FormGroup>
            <FormGroup
              controlId="formLastForm"
              validationState={this.state.accountLastFourValid
                ? 'success'
                : 'error'}>
                <ControlLabel>Last Four Digits (Optional)</ControlLabel>
                <FormControl
                  type='number'
                  step='0'
                  bsSize='large'
                  onChange={() => this.validateAddAccountForm()}
                  disabled={this.state.addAccountInProgress}
                  autoComplete='off'
                  inputRef={(ref) => {this.lastFour = ref}}
                  placeholder='Last four digits of account number'/>
            </FormGroup>
            <FormGroup
              controlId="formBalance"
              validationState={this.state.accountBalanceValid
                ? 'success'
                : 'error'}>
                <ControlLabel>Balance</ControlLabel>
                <FormControl
                  type='number'
                  step='0.00'
                  bsSize='large'
                  onChange={() => this.validateAddAccountForm()}
                  disabled={this.state.addAccountInProgress}
                  autoComplete='off'
                  inputRef={(ref) => {this.balance = ref}}
                  placeholder='Balance'
                  defaultValue='0.00'/>
            </FormGroup>
            <FormGroup
              className='pull-right'>
              <Button
                bsStyle='success'
                disabled={!this.state.accountNameValid
                  || !this.state.accountBalanceValid
                  || !this.state.accountLastFourValid
                  || this.state.addAccountInProgress}
                type="submit"
                onClick={this.addAccount.bind(this)}>
                {this.state.addAccountInProgress
                  ? 'Adding account...'
                  : 'Add Account'}
              </Button>
            </FormGroup>
          </Form>
        </div>
        <Modal
          dialogComponentClass='modal'
          show={this.state.addAccountInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </div>
    );
  }
}
