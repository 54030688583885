import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class DeleteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDeleteInProgress: false
    };
  }

  deleteUser(e) {
    e.preventDefault();
    this.setState({userDeleteInProgress: true}, () => {
      $.ajax({
        method: 'DELETE',
        url: API_ROUTE + '/user/'
          + this.props.user.email,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        dataType: 'json',
        cache: false,
        crossOrigin: true,
        contentType: 'application/json; charset=utf-8;'
      })
      .done((data) => {
        this.props.setUser();
        this.props.history.push('/');
      })
      .fail((data) => {
        console.log(data.responseText);
      });
    });
  }

  render() {
    return (
      <Form
        className='settingsForm'
        fluid='true'>
        <FormGroup
          controlId="formUser">
          <ButtonToolbar
          className='pull-left'>
            <Button
              bsStyle='danger'
              className='loginButton'
              disabled={this.state.userDeleteInProgress}
              type="submit"
              onClick={(e) => {
                if (window.confirm(
                  'Are you sure? This action cannot be undone.'
                )) {
                  this.deleteUser(e);
                } else {
                  e.preventDefault();
                }
              }}>
              {this.state.userDeleteInProgress
                ? 'Deleting profile...'
                : 'Delete Profile'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.userDeleteInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
