import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import '../App.css';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerCallInProgress: false,
      registerNameValid: false,
      registerEmailValid: false,
      registerPasswordValid: false,
      registerPasswordMatched: false,
      registerErrorText: null,
      passwordErrorText: null,
      passwordMatchText: null,
      updating: false
    }
  }

  validateLoginForm() {
    var userNameGood, passwordGood, userEmailGood, passwordMatch;
    //check user name field
    if (!!this.userName.value && this.userName.value.length <= 25) {
      userNameGood = true;
    } else {
      userNameGood = false;
    }
    //check user email field
    if (!!this.userEmail.value && this.userEmail.value
          .match(/^[A-z.\-_0-9]+@[A-z.\-_0-9]+\.[A-z]{2,5}$/)
            && this.userEmail.value.length <= 450) {
      userEmailGood = true;
    } else {
      userEmailGood = false;
    }
    //check user password field
    if (!!this.password.value && this.password.value.length >= 8
          && this.password.value
            .match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)
          && this.password.value.length <= 25) {
      passwordGood = true;
    } else {
      passwordGood = false;
    }
    //check user password fields match
    if (this.password.value === this.passwordMatch.value
          && this.password.value.length >= 8
          && this.password.value.length <= 25) {
      passwordMatch = true;
    } else {
      passwordMatch = false;
    }

    this.setState({
      registerNameValid: userNameGood,
      registerEmailValid: userEmailGood,
      registerPasswordValid: passwordGood,
      registerPasswordMatched: passwordMatch
    });
  }

  register(e) {
    e.preventDefault();
    try {
      var name = this.userName.value;
      var password = this.password.value;
      var email = this.userEmail.value;
      this.setState({registerCallInProgress: true}, () => {
        $.ajax({
          method: 'POST',
          url: API_ROUTE + '/user/',
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          data: JSON.stringify({
            name: name,
            password: btoa(password),
            email: email.toLowerCase()
          }),
          headers: {
            "access-control-allow-origin": API_ROUTE,
          }
        }).done((data) => {
          this.setState({
            registerErrorText: null,
            registerCallInProgress: false
          }, () => {
            this.props.setUser(data);
          });
        }).fail((data) => {
          var errorString;
          if (data.status === 503 || data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.';
          } else {
            errorString = data.responseText.replace(/"/g, '');
          }
          this.setState({
            registerErrorText: errorString,
            registerCallInProgress: false,
          });
        });
      });
    } catch (e) {
      console.log(e);
      this.setState({
        registerErrorText: 'Web application down. Please try again later.',
        registerCallInProgress: false,
      });
    }
  }

  render() {
    return (
      <Form
        className='loginForm'
        fluid='true'
        autoComplete='off'>
      <FormGroup
        controlId="loginError"
        validationState='error'
        hidden={!this.state.registerErrorText}>
        <ControlLabel>{this.state.registerErrorText}</ControlLabel>
      </FormGroup>
        <FormGroup
          controlId="formEmail"
          validationState={this.state.registerEmailValid ? 'success' : 'error'}
          autoComplete='off'>
          <ControlLabel>Email</ControlLabel>
          <FormControl bsSize='large'
            onChange={this.validateLoginForm.bind(this)}
            disabled={this.state.registerCallInProgress}
            autoComplete='off'
            inputRef={(ref) => {this.userEmail = ref}}
            type="email"
            placeholder="Email"/>
        </FormGroup>
        <FormGroup
          controlId="formName"
          validationState={this.state.registerNameValid ? 'success' : 'error'}
          autoComplete='off'>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            bsSize='large'
            onChange={this.validateLoginForm.bind(this)}
            disabled={this.state.registerCallInProgress}
            autoComplete='off'
            inputRef={(ref) => {this.userName = ref}}
            type="text"
            placeholder="Name"/>
        </FormGroup>
        <FormGroup
          controlId="formPassword"
          validationState={this.state.registerPasswordValid
            ? 'success'
            : 'error'}
           autoComplete='off'>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            bsSize='large'
            onChange={this.validateLoginForm.bind(this)}
            disabled={this.state.registerCallInProgress}
            autoComplete='off'
            inputRef={(ref) => {this.password = ref}}
            type="password"
            placeholder="Password"/>
        </FormGroup>
        <FormGroup
          controlId="formPasswordMatch"
          validationState={this.state.registerPasswordMatched
            ? 'success'
            : 'error'}
          autoComplete='off'>
          <ControlLabel>Verify Password</ControlLabel>
          <FormControl
            bsSize='large'
            onChange={this.validateLoginForm.bind(this)}
            disabled={this.state.registerCallInProgress}
            autoComplete='off'
            inputRef={(ref) => {this.passwordMatch = ref}}
            type="password"
            placeholder="Password"/>
        </FormGroup>
        <FormGroup>
          <Button
            onClick={() => this.props.setUser()}
            disabled={this.state.registerCallInProgress}>
            Back to log in
          </Button>
          <ButtonToolbar
          className='pull-right'>
            <Button
              bsStyle='success'
              disabled={!this.state.registerNameValid
                || !this.state.registerEmailValid
                || !this.state.registerPasswordValid
                || !this.state.registerPasswordMatched
                || this.state.registerCallInProgress}
              type='submit'
              onClick={this.register.bind(this)}>
              {this.state.registerCallInProgress
                ? 'Registering...'
                : 'Register'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.registerCallInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
