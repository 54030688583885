export function getLocalDateISO() {
    // get current date, ISO string for localdate will be in UTC timezone  
    var isoTime = new Date();
    // subtract the timezone offset to get the accurate local date for
    isoTime = new Date(isoTime - isoTime.getTimezoneOffset() * 60 * 1000);    
    return isoTime.toISOString().slice(0, -1);
}

export function getCurrentUTCDateISO() {
    var isoTime = new Date(Date.now());
    return isoTime.toISOString().slice(0, -1);
}

export function formatCurrency(value) {
    var output = Math.abs(value).toLocaleString('en-US', {
        style: 'currency', currency: 'USD'
    });

    if (value < 0) {
        output = '-' + output;
    }

    return output;
}