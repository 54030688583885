import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class AddBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankInsertInProgress: false,
      bankNameValid: false,
      bankAddErrorText: null
    };
  }

  validateAddForm() {
    var nameValid;

    if (this.name.value !== null && this.name.value.length > 0
          && this.name.value.length <= 20) {
      nameValid = true;
    } else {
      nameValid = false;
    }

    this.setState({
      bankNameValid: nameValid
    });
  }

  addBank(e) {
    e.preventDefault();
    this.setState({
      bankInsertInProgress: true
    }, () => {
      $.ajax({
        method: 'POST',
        url: API_ROUTE + '/bank/',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        data: JSON.stringify({
          name: this.name.value
        })
      }).done((data) => {
        this.name.value = '';
        this.setState({bankAddErrorText: null});
        this.props.getBanks();
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          bankAddErrorText: errorString
        });
      }).always(() => {
        this.setState({
          bankInsertInProgress: false,
          bankNameValid: false
        });
      });
    });
  }

  render() {
    return (
      <Form
        className='settingsForm'
        fluid='true'>
        <FormGroup
          controlId="addError"
          validationState='error'
          hidden={!this.state.bankAddErrorText}>
          <ControlLabel>{this.state.bankAddErrorText}</ControlLabel>
        </FormGroup>
        <FormGroup
          controlId="formName"
          validationState={this.state.bankNameValid ? 'success' : 'error'}>
          <ControlLabel>Add bank</ControlLabel>
          <FormControl
            bsSize='large'
            onChange={this.validateAddForm.bind(this)}
            disabled={this.state.bankInsertInProgress}
            inputRef={(ref) => {this.name = ref}}
            type='text'
            placeholder='New bank name'>
          </FormControl>
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='success'
              className='loginButton'
              disabled={this.state.bankInsertInProgress
                || !this.state.bankNameValid}
              type="submit"
              onClick={this.addBank.bind(this)}>
              {this.state.bankInsertInProgress ? 'Adding bank...' : 'Add'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.bankInsertInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
