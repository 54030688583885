import React, { Component } from 'react';
import {Switch, Route, withRouter} from 'react-router';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import NavbarComponent from './components/navbar';
import HomePage from './components/home';
import BankPage from './components/bank';
import AccountPage from './components/account';
import EditTransaction from './components/editTransaction';
import RegisterPage from './components/register';
import LoginPage from './components/login';
import SettingsPage from './components/settings';
import EditAccount from './components/editAccount';
import AnalyzePage from './components/analyze';
import ReportPage from './components/report';
import * as $ from 'jquery';
import * as TransactionTypeHelper from './classes/TransactionTypeHelper';
import { API_ROUTE } from './index';

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      registerUser: false,
      accounts: null,
      banks: null,
      payees: null,
      payers: null,
      categories: null,
      updating: false,
      payerNoneId: null,
      payeeNoneId: null,
      categoryNoneId: null
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $(document).click(function (event) {
        var clickover = $(event.target);
        var opened = $(".navbar-collapse").hasClass("collapse in");
        if (opened && !clickover.parents('.navbar').length) {
          $("button.navbar-toggle").click();
        }
      });
    });
  }

  setUser(user) {
    this.props.history.push('/');
    this.setState({
      user: user,
      accounts: user ? this.getBanks(user.apiKey) : null,
      banks: user ? this.getAccounts(user.apiKey) : null,
      categories: user ? this.getCategories(user.apiKey) : null,
      payees: user ? this.getPayees(user.apiKey) : null,
      payers: user ? this.getPayers(user.apiKey) : null,
      registerUser: false
    });
  }

  setRegisterUser() {
    this.setState({
      user: null,
      registerUser: true
    });
  }

  getAccounts(apiKey = this.state.user.apiKey) {
    this.setState({updating: true});
    //get accounts for the user
    $.ajax({
      method: 'GET',
      url: API_ROUTE + '/account',
      headers: {
        x_cba_apikey: apiKey,
        "access-control-allow-origin": API_ROUTE,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      if (data) {
        var mapData = new Map();
        for (var i = 0; i < data.length; i++) {
          mapData.set(data[i].id, data[i]);
        }
        this.setState({accounts: mapData});
      } else {
        this.setState({accounts: new Map()});
      }
    })
    .fail((data) => {
      console.log(data);
      this.setState({accounts: new Map()});
    })
    .always(() => {
      this.setState({updating: false});
    });
  }

  getBanks(apiKey = this.state.user.apiKey) {
    this.setState({updating: true});
    //get banks for the user
    $.ajax({
      method: 'GET',
      url: API_ROUTE + '/bank',
      headers: {
        x_cba_apikey: apiKey,
        "access-control-allow-origin": API_ROUTE,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      if (data) {
        var mapData = new Map();
        for (var i = 0; i < data.length; i++) {
          mapData.set(data[i].id, data[i]);
        }
        this.setState({banks: mapData});
      } else {
        this.setState({banks: new Map()});
      }
    })
    .fail((data) => {
      console.log(data);
      this.setState({banks: new Map()});
    })
    .always(() => {
      this.setState({updating: false});
    });
  }

  getPayees(apiKey = this.state.user.apiKey) {
    this.setState({updating: true});
    //get banks for the user
    $.ajax({
      method: 'GET',
      url: API_ROUTE + '/payee',
      headers: {
        x_cba_apikey: apiKey,
        "access-control-allow-origin": API_ROUTE,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      if (data) {
        var mapData = new Map();
        var noneId;
        for (var i = 0; i < data.length; i++) {
          var id = data[i].id;
          mapData.set(id, data[i]);
          if (TransactionTypeHelper.SpecialCategoryEnum.valueOf(data[i].name) === TransactionTypeHelper.SpecialCategoryEnum.NONE) {
            noneId = id;
          } 
        }
        this.setState({
          payees: mapData,
          payeeNoneId: noneId
        });
      } else {
        this.setState({payees: new Map()});
      }
    })
    .fail((data) => {
      console.log(data);
      this.setState({payees: new Map()});
    })
    .always(() => {
      this.setState({updating: false});
    });
  }

  getPayers(apiKey = this.state.user.apiKey) {
    this.setState({updating: true});
    //get banks for the user
    $.ajax({
      method: 'GET',
      url: API_ROUTE + '/payer',
      headers: {
        x_cba_apikey: apiKey,
        "access-control-allow-origin": API_ROUTE,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      if (data) {
        var mapData = new Map();
        var noneId;
        for (var i = 0; i < data.length; i++) {
          var id = data[i].id;
          mapData.set(id, data[i]);
          if (TransactionTypeHelper.SpecialCategoryEnum.valueOf(data[i].name) === TransactionTypeHelper.SpecialCategoryEnum.NONE) {
            noneId = id;
          } 
        }
        this.setState({
          payers: mapData,
          payerNoneId: noneId
        });
      } else {
        this.setState({payers: new Map()});
      }
    })
    .fail((data) => {
      console.log(data);
      this.setState({payers: new Map()});
    })
    .always(() => {
      this.setState({updating: false});
    });
  }

  getCategories(apiKey = this.state.user.apiKey) {
    this.setState({updating: true});
    //get banks for the user
    $.ajax({
      method: 'GET',
      url: API_ROUTE + '/category',
      headers: {
        x_cba_apikey: apiKey,
        "access-control-allow-origin": API_ROUTE,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      if (data) {
        var mapData = new Map();
        var noneId;
        for (var i = 0; i < data.length; i++) {
          var id = data[i].id;
          mapData.set(id, data[i]);
          if (TransactionTypeHelper.SpecialCategoryEnum.valueOf(data[i].name) === TransactionTypeHelper.SpecialCategoryEnum.NONE) {
            noneId = id;
          } 
        }
        this.setState({
          categories: mapData,
          categoryNoneId: noneId
        });
      } else {
        this.setState({categories: new Map()});
      }
    })
    .fail((data) => {
      console.log(data);
      this.setState({categories: new Map()});
    })
    .always(() => {
      this.setState({updating: false});
    });
  }

  refreshUserData() {
    this.getPayees();
    this.getPayers();
    this.getBanks();
    this.getAccounts();
    this.getCategories();
  }

  getBody() {
    let body;
    if (this.state.registerUser) {
      body = (
        <div
          className='loginFormWrapper'>
          <RegisterPage
            setUser={this.setUser.bind(this)} />
        </div>
      );
    } else if (!this.state.user) {
      body = (
        <div
          className='loginFormWrapper'>
          <LoginPage
            setUser={this.setUser.bind(this)}
            goToRegisterPage={this.setRegisterUser.bind(this)}/>
        </div>
      );
    } else {
      body = (
        <div>
          <NavbarComponent
            user={this.state.user}
            logout={this.setUser.bind(this)}
            accounts={this.state.accounts}
            banks={this.state.banks}
            categories={this.state.categories}
            payees={this.state.payees}
            payers={this.state.payers}/>
          <div
            className='content'
            id='content'>
            <Switch>
              <Route
                path='/'
                exact
                render={(props) => <HomePage
                                    {...props}
                                    user={this.state.user}
                                    getBanks={this.getBanks.bind(this)}
                                    getPayees={this.getPayees.bind(this)}
                                    getPayers={this.getPayers.bind(this)}
                                    getCategories={this.getCategories
                                      .bind(this)}/>
              }/>
              <Route
                path='/account/:accountId'
                render={(props) => <AccountPage
                                    {...props}
                                    user={this.state.user}
                                    payees={this.state.payees}
                                    payers={this.state.payers}
                                    accounts={this.state.accounts}
                                    history={this.props.history}
                                    getAccounts={this.getAccounts.bind(this)}
                                    categories={this.state.categories}
                                    payerNoneId={this.state.payerNoneId}
                                    payeeNoneId={this.state.payeeNoneId}
                                    categoryNoneId={this.state.categoryNoneId}/>
              }/>
              <Route
                path='/bank/:bankId'
                render={(props) => <BankPage
                                    {...props}
                                    user={this.state.user}
                                    getAccounts={this.getAccounts.bind(this)}
                                    history={this.props.history}/>
              }/>
              <Route
                path='/transaction'
                render={(props) => <EditTransaction
                                    {...props}
                                    user={this.state.user}
                                    payees={this.state.payees}
                                    payers={this.state.payers}
                                    accounts={this.state.accounts}
                                    categories={this.state.categories}
                                    history={this.props.history}
                                    getAccounts={this.getAccounts.bind(this)}/>
              }/>
              <Route
                path='/settings'
                render={(props) => <SettingsPage
                                    {...props}
                                    user={this.state.user}
                                    payees={this.state.payees}
                                    payers={this.state.payers}
                                    categories={this.state.categories}
                                    banks={this.state.banks}
                                    setUser={this.setUser.bind(this)}
                                    history={this.props.history}
                                    getPayees={this.getPayees.bind(this)}
                                    getPayers={this.getPayers.bind(this)}
                                    getCategories={this.getCategories
                                      .bind(this)}
                                    getBanks={this.getBanks.bind(this)}
                                    getAccounts={this.getAccounts.bind(this)}
                                    refreshUserData={this.refreshUserData.bind(this)}/>
              }/>
              <Route
                path='/editAccount'
                render={(props) => <EditAccount
                                    {...props}
                                    user={this.state.user}
                                    getAccounts={this.getAccounts.bind(this)}/>
              }/>
              <Route
                path='/analyze'
                render={(props) => <AnalyzePage
                                    {...props}
                                    user={this.state.user}
                                    payees={this.state.payees}
                                    payers={this.state.payers}
                                    categories={this.state.categories}
                                    banks={this.state.banks}
                                    accounts={this.state.accounts}/>
              }/>
              <Route
                path='/report'
                render={(props) => <ReportPage
                                    {...props}
                                    user={this.state.user}
                                    payees={this.state.payees}
                                    payers={this.state.payers}
                                    categories={this.state.categories}
                                    banks={this.state.banks}
                                    accounts={this.state.accounts}/>
              }/>
            </Switch>
          </div>
        </div>
      );
    }

    return body;
  }

  render() {
    return (
      <div
        className='fixedBody'>
        <Modal
          dialogComponentClass='modal'
          show={(this.state.user && !(this.state.accounts && this.state.banks
            && this.state.categories && this.state.payees && this.state.payers))
            || this.state.updating}>
          <Loader color="#FFF"/>
        </Modal>
        {this.getBody()}
      </div>
    );
  }
}

export default withRouter(App);
