import React, {Component} from 'react';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { getLocalDateISO } from '../classes/TransactionHelper';
import { TransactionTypeEnum } from '../classes/TransactionTypeHelper';

export default class Analyze extends Component {
  constructor(props) {
    super(props);
    var curTime = getLocalDateISO().split('T')[0];    
    this.state = {
      type: 'All',
      bankId: null,
      accountId: null,
      categoryId: null,
      foreignId: null,
      startDate: '2017-01-01',
      endDate: curTime,      
      minAmount: '0.00',
      maxAmount: '1000000000.00',      
      errorText: null,
      minAmountValid: true,
      maxAmountValid: true,
      rangeValid: true,
      startDateValid: true,
      endDateValid: true,
      datesValid: true
    }; 
    this.minDate = new Date('2017-01-01T00:00:00Z').getTime();   
  }  

  validateDates() {
    var valid = true;
    var startDateValid = true;
    var endDateValid = true;
    var lowDate = new Date(this.state.startDate).getTime();
    var highDate = new Date(this.state.endDate).getTime();        

    if (this.state.startDate === 'T00:00:00Z' || lowDate < this.minDate) {
      startDateValid = false;
    }
    if (this.state.endDate === 'T00:00:00Z' || highDate < this.minDate) {
      endDateValid = false;
    }
    if (highDate < lowDate) {
      valid = false;
    }

    this.setState({
      startDateValid: startDateValid,
      endDateValid: endDateValid,
      datesValid: valid
    });
  }

  validateMin(e) {
    e = e.currentTarget.value
    var valid = false;
    var rangeValid = false;

    if (e !== '' && e.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
      valid = true;
    }
    if (this.state.maxAmountValid && valid) {
        rangeValid = Number(e) <= Number(this.state.maxAmount);
    }

    this.setState({
      minAmount: e, minAmountValid: valid, rangeValid: rangeValid
    });
  }

  validateMax(e) {
    e = e.currentTarget.value
    var valid = false;
    var rangeValid = false

    if (e !== '' && e.match(/^[0-9]*(\.[0-9]{0,2})?$/)
      && Number(e) >= Number(this.state.minAmount)) {
      valid = true;
    }
    if (this.state.minAmountValid && valid) {
        rangeValid = Number(e) >= Number(this.state.minAmount);
    }

    this.setState({
      maxAmount: e, maxAmountValid: valid, rangeValid: rangeValid
    });
  }

  getBody() {
    var body;
    if (this.props.accounts && this.props.banks && this.props.payees
        && this.props.payers && this.props.categories) {
      if (this.props.accounts.size > 0) {
        var reportLink = '/report' +
          '?type=' + this.state.type +
          '&minAmount=' + this.state.minAmount +
          '&maxAmount=' + this.state.maxAmount +
          '&startDate=' + this.state.startDate +
          '&endDate=' + this.state.endDate +
          (this.state.foreignId ? ('&foreignId=' + this.state.foreignId) : '') +
          (this.state.categoryId ? ('&categoryId=' + this.state.categoryId) : '') +
          (this.state.accountId ? ('&accountId=' + this.state.accountId) : '') +
          (this.state.bankId ? ('&bankId=' + this.state.bankId) : '');
        
        var categories = [
          <option
            value=''
            key='categoryAll'>
            All
          </option>
        ];
        var accounts = [
          <option
            value=''
            key='accountAll'>
            All
          </option>
        ];
        var banks = [
          <option
            value=''
            key='bankAll'>
            All
          </option>
        ];
        var foreigns = [
          <option
            value=''
            key='foreignAll'>
            All
          </option>
        ];

        var foreignSelection;
        var foreignLabel;

        this.props.categories.forEach((value) => {
          categories.push(
            <option
              value={value.id}
              key={'category#' + value.id}>
              {value.name}
            </option>
          );
        });

        this.props.banks.forEach((value) => {
            banks.push(
              <option
                value={value.id}
                key={'bank#' + value.id}>
                {value.name}
              </option>);
        });

        this.props.accounts.forEach((value) => {
            if (!this.state.bankId ||
              (this.state.bankId && this.state.bankId === value.bankId.toString())) {
                accounts.push(
                  <option
                    value={value.id}
                    key={'account#' + value.id}>
                    {value.name}
                  </option>
                );
            }
        });

        if (this.state.type !== TransactionTypeEnum.ALL) {
          switch (this.state.type) {
            case TransactionTypeEnum.DEPOSIT:
              this.props.payers.forEach((value) => {
                foreigns.push(
                  <option
                    value={value.id}
                    key={'payer#' + value.id}>
                    {value.name}
                  </option>
                );
              });
              foreignLabel = 'Deposits from';
              break;
            case TransactionTypeEnum.WITHDRAWAL:
              this.props.payees.forEach((value) => {
                foreigns.push(
                  <option
                    value={value.id}
                    key={'payee#' + value.id}>
                    {value.name}
                  </option>
                );
              });
              foreignLabel = 'Withdrawals for';
              break;
            case TransactionTypeEnum.TRANSFER:
              this.props.accounts.forEach((value) => {
                if (!this.state.accountId ||
                  (this.state.accountId && this.state.accountId !== value.id.toString())) {
                  foreigns.push(
                    <option
                      value={value.id}
                      key={'account#' + value.id}>
                      {value.name}
                    </option>
                  );
                }
              });
              foreignLabel = 'Transfers to';
              break;
            default:
              throw new Error("Invalid transaction type");
          }

          foreignSelection = (
            <FormGroup
              controlId='formType'>
              <ControlLabel>{foreignLabel}</ControlLabel>
              <FormControl
                componentClass='select'
                placeholder='typeSelect'
                bsSize='large' defaultValue=''
                onChange={(e) => this.setState({
                  foreignId: !e.currentTarget.value
                    ? null
                    : e.currentTarget.value
                })}>
                {foreigns}
              </FormControl>
            </FormGroup>
          );
        }

        body = (
          <div>
            <h3>
              Analyze banks/accounts
            </h3>
            <Form
              className='settingsForm'
              fluid='true'>
              <FormGroup
                controlId='formBank'>
                <ControlLabel>Bank</ControlLabel>
                <FormControl
                  componentClass='select'
                  placeholder='typeSelect'
                  bsSize='large'
                  defaultValue=''
                  onChange={(e) => this.setState({
                    bankId: !e.currentTarget.value
                      ? null
                      : e.currentTarget.value
                  })}>
                  {banks}
                </FormControl>
              </FormGroup>
              <FormGroup
                controlId='formAccount'>
                <ControlLabel>Account</ControlLabel>
                <FormControl
                  componentClass='select'
                  placeholder='typeSelect'
                  bsSize='large'
                  defaultValue=''
                  onChange={(e) => this.setState({
                    accountId: !e.currentTarget.value
                      ? null
                      : e.currentTarget.value
                  })}>
                  {accounts}
                </FormControl>
              </FormGroup>
              <FormGroup
                controlId='formType'>
                <ControlLabel>Type</ControlLabel>
                <FormControl
                  componentClass='select'
                  placeholder='typeSelect'
                  bsSize='large'
                  defaultValue={TransactionTypeEnum.ALL}
                  onChange={(e) => this.setState({
                    type: e.currentTarget.value
                  })}>
                  <option
                    value={TransactionTypeEnum.ALL}
                    key={'allType'}>
                    {TransactionTypeEnum.ALL}
                  </option>
                  <option
                    value={TransactionTypeEnum.DEPOSIT}
                    key={'depositType'}>
                    {TransactionTypeEnum.DEPOSIT}
                  </option>
                  <option
                    value={TransactionTypeEnum.WITHDRAWAL}
                    key={'withdrawalType'}>
                    {TransactionTypeEnum.WITHDRAWAL}
                  </option>
                  {/* <option
                    value={TransactionTypeEnum.TRANSFER}
                    key={'transferType'}>
                    {TransactionTypeEnum.TRANSFER}
                  </option> */}
                </FormControl>
              </FormGroup>
              {foreignSelection}
              <FormGroup
                controlId='formCategory'>
                <ControlLabel>Category</ControlLabel>
                <FormControl
                  componentClass='select'
                  placeholder='typeSelect'
                  bsSize='large'
                  defaultValue=''
                  onChange={(e) => this.setState({
                    categoryId: !e.currentTarget.value
                      ? null
                      : e.currentTarget.value
                  })}>
                  {categories}
                </FormControl>
              </FormGroup>
              <FormGroup
                controlId="startDate"
                validationState={this.state.datesValid
                    && this.state.startDateValid
                  ? 'success'
                  : 'error'}>
                <ControlLabel>Start Date</ControlLabel>
                <FormControl
                  bsSize='large'
                  type='date'
                  value={this.state.startDate.split('T')[0]}
                  onChange={(e) => {                    
                    this.setState({
                      startDate: e.target.value + 'T00:00:00Z'
                    }, () => {
                      this.validateDates();
                    });
                  }}/>
              </FormGroup>
              <FormGroup
                controlId="endDate"
                validationState={this.state.datesValid
                    && this.state.endDateValid
                  ? 'success'
                  : 'error'}>
                <ControlLabel>End Date</ControlLabel>
                <FormControl
                  bsSize='large'
                  type='date'
                  value={this.state.endDate.split('T')[0]}
                  onChange={(e) => {
                    this.setState({
                      endDate: e.target.value + 'T00:00:00Z'
                    }, () => {
                      this.validateDates();
                    });
                  }}/>
              </FormGroup>
              <FormGroup
                controlId='minAmount'
                validationState={this.state.minAmountValid
                    && this.state.rangeValid
                  ? 'success'
                  : 'error'}>
                <ControlLabel>Minimum Amount</ControlLabel>
                <FormControl
                  type='number'
                  step='0.00'
                  bsSize='large'
                  onChange={(e) => this.validateMin(e)}
                  value={this.state.minAmount}/>
              </FormGroup>
              <FormGroup
                controlId='maxAmount'
                validationState={this.state.maxAmountValid
                      && this.state.rangeValid
                  ? 'success'
                  : 'error'}>
                <ControlLabel>Maximum Amount</ControlLabel>
                <FormControl
                  type='number'
                  step='0.00'
                  bsSize='large'
                  onChange={(e) => this.validateMax(e)}
                  value={this.state.maxAmount}/>
              </FormGroup>
              <FormGroup>
                <ButtonToolbar
                  className='pull-right'>
                  <Button
                    bsStyle='success'
                    disabled={!(this.state.minAmountValid
                      && this.state.maxAmountValid && this.state.rangeValid
                      && this.state.datesValid && this.state.startDateValid
                      && this.state.endDateValid)
                    }
                    onClick={() => this.props.history.push(reportLink)}>
                    Generate Report
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </div>
        );
      } else {
        body = (
          <div>
            No accounts to analyze.
          </div>
        );
      }
    } else {
      body = (
        <div>
          Loading...
        </div>
      );
    }

    return body;
  }

  render() {
    return (
      this.getBody()
    );
  }
}
