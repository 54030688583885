import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {
  Navbar, Nav, NavItem, NavDropdown, MenuItem
} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import * as TransactionHelper from '../classes/TransactionHelper';

export default class NavbarComponent extends Component {
  getBanks() {
    var output = [];
    if (this.props.banks && this.props.payees && this.props.payers
      && this.props.categories) {
      this.props.banks.forEach((value, id) => {
        var accounts = [];
        var totalBalance = 0;
        var bankLink = '/bank/' + id;
        if (this.props.accounts) {
          this.props.accounts.forEach((avalue, aid) => {
            if (avalue.bankId === id) {
              var accountLink = '/account/' + aid;
              totalBalance +=  Number(avalue.balance);
              accounts.push(
                <LinkContainer
                  key={aid+'lc'}
                  to={accountLink}>
                  <MenuItem>
                    {avalue.name + ': ' + TransactionHelper.formatCurrency(avalue.balance)}
                  </MenuItem>
                </LinkContainer>
              );
            }
          });
        }
        output.push(
          <NavDropdown
            key={id+'bdd'}
            id='basic-nav-dropdown'
            title={value.name + ': ' + TransactionHelper.formatCurrency(totalBalance)}>
            {accounts}
            <LinkContainer
              to={bankLink}>
              <MenuItem>
                Add new account
              </MenuItem>
            </LinkContainer>
          </NavDropdown>
        );
      });
    } else {
      output.push(
        <Navbar.Text
          key='loading'>
          {'\t'}Loading...
        </Navbar.Text>
      );
    }
    return output;
  }

  render() {
    return (
      <Navbar
        fluid
        inverse
        collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link
              to='/'>
              CheckbookAnywhere
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle/>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <LinkContainer
              eventKey={'homeItem'}
              to='/'>
              <NavItem>Home</NavItem>
            </LinkContainer>
            {this.getBanks()}
            <LinkContainer
              eventKey={'analyzeItem'}
              to ='/analyze'>
              <NavItem>Analyze</NavItem>
            </LinkContainer>
            <LinkContainer
              eventKey={'settingsItem'}
              to='/settings'>
              <NavItem>Settings</NavItem>
            </LinkContainer>
            <NavItem
              eventKey={'logout'}
              onClick={() => this.props.logout()}>
              Log out
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
