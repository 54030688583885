import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class DeletePayee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payeeDeleteInProgress: false,
      payeeDeleteErrorText: null
    };
  }

  deletePayee(e) {
    e.preventDefault();
    this.setState({
      payeeDeleteInProgress: true
    }, () => {
      $.ajax({
        method: 'DELETE',
        url: API_ROUTE + '/payee/'
          + ReactDOM.findDOMNode(this.payeeSelect).value,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        }
      }).done((data) => {
          this.props.getPayees();
          this.setState({payeeDeleteErrorText: null});
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          payeeDeleteErrorText: errorString
        });
        setTimeout(() => this.setState({payeeDeleteErrorText: null}), 5000);
      }).always(() => {
        this.setState({payeeDeleteInProgress: false});
      });
    });
  }

  getPayees() {
    var dropdown;
    var payees = [];

    this.props.payees.forEach((value, key) => {
      payees.push(
        <option
          value={key}
          key={key + 'payee'}>
          {value.name}
        </option>
      );
    });

    dropdown = (
      <FormControl
        componentClass='select'
        placeholder='payeeSelect'
        bsSize='large'
        disabled={this.state.payeeDeleteInProgress}
        defaultValue=''
        ref={select => { this.payeeSelect = select }}>
        {payees}
      </FormControl>
    );

    return dropdown;
  }

  render() {
    return (
      <Form
        className='settingsForm'
        fluid='true'>
        <FormGroup
          controlId="deleteError"
          validationState='error'
          hidden={!this.state.payeeDeleteErrorText}>
          <ControlLabel>{this.state.payeeDeleteErrorText}</ControlLabel>
        </FormGroup>
        <FormGroup
          controlId="formPayeeId">
          <ControlLabel>Delete payees</ControlLabel>
          {this.getPayees()}
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='danger'
              className='loginButton'
              disabled={this.state.payeeDeleteInProgress}
              type="submit"
              onClick={(e) => {
                if (window.confirm(
                  'Are you sure? This action cannot be undone.'
                )) {
                  this.deletePayee(e);
                } else {
                  e.preventDefault();
                }
              }}>
              {this.state.payeeDeleteInProgress
                ? 'Deleting payee...'
                : 'Delete'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.payeeDeleteInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
