export default class colorpicker {
  get(num) {
    return css_color_names.slice(0, num);
  }
}

const css_color_names = [
  "green",
  "red",
  "yellow",
  "blue",
  "black",
  "orange",
  "purple",
  "grey",
  "brown",
  "white",
  "aliceblue",
  "antiquewhite",
  "aqua",
  "burlywood",
  "cadetblue",
  "chartreuse",
  "chocolate",
  "coral",
  "cornflowerblue",
  "cornsilk",
  "crimson",
  "cyan",
  "darkblue",
  "goldenrod",
  "beige",
  "azure",
  "bisque",
  "blanchedalmond",
  "blueviolet",
  "aquamarine",
  "greenyellow",
  "honeydew",
  "hotpink",
  "indianred",
  "indigo",
  "ivory",
  "khaki",
  "lavender",
  "lightcoral",
  "darkcyan",
  "darkgoldenrod",
  "darkgrey",
  "darkorange",
  "darkorchid",
  "darkred",
  "lightseagreen",
  "lightskyblue",
  "mediumpurple",
  "mediumseagreen",
  "mediumslateblue",
  "lightslategrey",
  "lightsteelblue",
  "darksalmon",
  "darkturquoise",
  "darkviolet",
  "deeppink",
  "deepskyblue",
  "dimgrey",
  "dodgerblue",
  "firebrick",
  "floralwhite",
  "forestgreen",
  "fuchsia",
  "gainsboro",
  "ghostwhite",
  "gold",
  "lightcyan",
  "limegreen",
  "linen",
  "magenta",
  "lightgoldenrodyellow",
  "lightgrey",
  "lightgreen",
  "lightpink",
  "darkgreen",
  "darkkhaki",
  "darkmagenta",
  "darkolivegreen",
  "lightsalmon",
  "lightyellow",
  "lime",
  "maroon",
  "mediumaquamarine",
  "mediumblue",
  "mediumorchid",
  "darkseagreen",
  "darkslateblue",
  "darkslategrey",
  "mediumspringgreen",
  "violet",
  "wheat",
  "whitesmoke",
  "mediumturquoise",
  "mediumvioletred",
  "midnightblue",
  "mintcream",
  "mistyrose",
  "moccasin",
  "navajowhite",
  "navy",
  "oldlace",
  "olive",
  "olivedrab",
  "orangered",
  "orchid",
  "palegoldenrod",
  "palegreen",
  "paleturquoise",
  "palevioletred",
  "papayawhip",
  "peachpuff",
  "peru",
  "pink",
  "plum",
  "powderblue",
  "rosybrown",
  "royalblue",
  "saddlebrown",
  "salmon",
  "sandybrown",
  "seagreen",
  "seashell",
  "sienna",
  "lavenderblush",
  "lawngreen",
  "lemonchiffon",
  "lightblue",
  "silver",
  "skyblue",
  "steelblue",
  "tan",
  "teal",
  "thistle",
  "slateblue",
  "slategrey",
  "snow",
  "springgreen",
  "tomato",
  "turquoise",
  "yellowgreen"
];
