export default class QueryParser {
  constructor(query) {
    this.queryMap = new Map();
    if (typeof query !== 'string') {
      throw Error("QueryParser constructor requires a string to be passed into it.");
    }
    //check if queyr string has the beginnning ? or not
    if (query[0] === '?') {
      query = query.substring(1);
    }
    //split all query arguments
    var queryArr = query.split('&');
    for (var i = 0; i < queryArr.length; i++) {
      var queryEntry = queryArr[i].split('=');
      this.queryMap.set(queryEntry[0], queryEntry[1]);
    }
  }

  get(key) {
    if (this.queryMap.has(key)) {
      return this.queryMap.get(key);
    }
    return null;
  }
}
