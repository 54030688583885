import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import QueryParser from '../classes/QueryParser';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class EditTransaction extends Component {
  constructor(props) {
    super(props);
    var query = new QueryParser(this.props.location.search);
    this.state = {
      transactionId: query.get('id'),
      transactionType: query.get('type'),
      transactionAmount: query.get('amount'),
      transactionDate: this.getDateTimeLocalFormat(query.get('date')),
      transactionForeignId: query.get('foreignid'),
      transactionCategoryId: query.get('categoryid'),
      transactionAccountId: query.get('accountId'),
      transactionAccountName: query.get('accountName'),
      amountValid: true,
      transactionChanged: false,
      amountErrorText: null,
      transactionDeleteInProgress: false,
      transactionUpdateInProgress: false,
      transactionUpdateErrorText: null
    }
  }

  getDateTimeLocalFormat(date) {
    var transactionDate = new Date(date);
    transactionDate.setTime(transactionDate.getTime()
      - transactionDate.getTimezoneOffset() * 60 * 1000);
    return transactionDate.toISOString().slice(0, -1);
  }

  validationUpdateForm() {
    var validAmount, errorText, changed;

    if (!this.amount.value.match(/^[0-9]+(\.[0-9]{0,2})?$/)
          && this.amount.value.length <= 16) {
      validAmount = false;
      errorText = 'Amount is not in an acceptable format';
    } else {
      if (this.amount.value <= 0) {
        validAmount = false;
        errorText = 'Amount must be greater than \'0\'';
      } else {
        validAmount = true;
      }
    }
    if (Number(this.amount.value)
        === Math.abs(Number(this.state.transactionAmount))
      && Number(ReactDOM.findDOMNode(this.categorySelect).value)
        === Number(this.state.transactionCategoryId)
      && Number(ReactDOM.findDOMNode(this.foreignSelect).value)
        === Number(this.state.transactionForeignId)) {
      changed = false;
    } else {
      changed = true;
    }

    this.setState({
      amountValid: validAmount,
      amountErrorText: errorText,
      transactionChanged: changed
    });
  }

  updateTransaction(e) {
    e.preventDefault();
    var accountLink = '/account/' + this.state.transactionAccountId;

    this.setState({transactionUpdateInProgress: true}, () => {
      if (this.state.transactionType === 'Deposit') {
        $.ajax({
          method: 'PUT',
          url: API_ROUTE + '/deposit/'
            + this.state.transactionId,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_apikey: this.props.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          },
          data: JSON.stringify({
            amount: this.amount.value,
            categoryId: ReactDOM.findDOMNode(this.categorySelect).value,
            foreignId: ReactDOM.findDOMNode(this.foreignSelect).value
          })
        }).done((data) => {
          this.props.getAccounts();
          this.props.history.push(accountLink);
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            transactionUpdateErrorText: errorString,
            transactionUpdateInProgress: false
          });
        });
      } else if (this.state.transactionType === 'Withdrawal') {
        $.ajax({
          method: 'PUT',
          url: API_ROUTE + '/withdrawal/'
            + this.state.transactionId,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_apikey: this.props.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          },
          data: JSON.stringify({
            amount: this.amount.value,
            categoryId: ReactDOM.findDOMNode(this.categorySelect).value,
            foreignId: ReactDOM.findDOMNode(this.foreignSelect).value
          })
        }).done((data) => {
          this.props.getAccounts();
          this.props.history.push(accountLink);
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            transactionUpdateErrorText: errorString,
            transactionUpdateInProgress: false
          });
        });
      } else if (this.state.transactionType === 'Transfer') {
        $.ajax({
          method: 'PUT',
          url: API_ROUTE + '/transfer/'
            + this.state.transactionId,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_apikey: this.props.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          },
          data: JSON.stringify({
            amount: this.amount.value,
            categoryId: ReactDOM.findDOMNode(this.categorySelect).value
          })
        }).done((data) => {
          this.props.getAccounts();
          this.props.history.push(accountLink);
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            transactionUpdateErrorText: errorString,
            transactionUpdateInProgress: false
          });
        });
      }
    });
  }

  deleteTransaction(e) {
    e.preventDefault();
    var accountLink = '/account/' + this.state.transactionAccountId;

    this.setState({transactionDeleteInProgress: true}, () => {
      if (this.state.transactionType === 'Deposit') {
        $.ajax({
          method: 'DELETE',
          url: API_ROUTE + '/deposit/'
            + this.state.transactionId,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_apikey: this.props.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          }
        }).done((data) => {
          this.props.getAccounts();
          this.props.history.push(accountLink);
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            transactionUpdateErrorText: errorString,
            transactionDeleteInProgress: false
          });
        });
      } else if (this.state.transactionType === 'Withdrawal') {
        $.ajax({
          method: 'DELETE',
          url: API_ROUTE + '/withdrawal/'
            + this.state.transactionId,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_apikey: this.props.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          }
        }).done((data) => {
          this.props.getAccounts();
          this.props.history.push(accountLink);
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            transactionUpdateErrorText: errorString,
            transactionDeleteInProgress: false
          });
        });
      } else if (this.state.transactionType === 'Transfer') {
        $.ajax({
          method: 'DELETE',
          url: API_ROUTE + '/transfer/'
            + this.state.transactionId,
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_apikey: this.props.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          }
        }).done((data) => {
          this.props.getAccounts();
          this.props.history.push(accountLink);
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            transactionUpdateErrorText: errorString,
            transactionDeleteInProgress: false
          });
        });
      }
    });
  }

  getForeigns() {
    var foreigns = [];
    var dropdown;
    var foreign;

    if (this.state.transactionType === 'Deposit') {
      foreign = this.props.payers;
    } else if (this.state.transactionType === 'Withdrawal') {
      foreign = this.props.payees;
    } else if (this.state.transactionType === 'Transfer') {
      foreign = this.props.accounts;
    }

    foreign.forEach((value, key) => {
      var add = true;
      if (this.state.transactionType === 'Transfer') {
        if (key === this.state.transactionAccountId.toString()) {
          add = false;
        }
      }
      if (add) {
        foreigns.push(
          <option
            value={key}
            key={key + 'foreign'}>
            {value.name}
          </option>
        );
      }
    });

    dropdown = (
      <FormControl
        componentClass='select'
        placeholder='categorySelect'
        bsSize='large'
        disabled={this.state.transactionUpdateInProgress
          || this.state.transactionDeleteInProgress}
        defaultValue={this.state.transactionForeignId}
        onChange={this.validationUpdateForm.bind(this)}
        ref={select => { this.foreignSelect = select }}>
        {foreigns}
      </FormControl>
    );

    return dropdown;
  }

  getCategories() {
    var dropdown;
    var categories = [];

    this.props.categories.forEach((value, key) => {
      categories.push(
        <option
          value={key}
          key={key + 'category'}>
          {value.name}
        </option>
      );
    });

    dropdown = (
      <FormControl
        componentClass='select'
        placeholder='categorySelect' bsSize='large'
        disabled={this.state.transactionUpdateInProgress
          || this.state.transactionDeleteInProgress}
        defaultValue={this.state.transactionCategoryId}
        onChange={this.validationUpdateForm.bind(this)}
        ref={select => { this.categorySelect = select }}>
        {categories}
      </FormControl>
    );

    return dropdown;
  }

  getBody() {
    var body;
    var accountLink = '/account/' + this.state.transactionAccountId;
    var foreignLabel;

    if (this.state.transactionType === 'Deposit') {
      foreignLabel = 'Deposit from';
    } else if (this.state.transactionType === 'Withdrawal') {
      foreignLabel = 'Withdrawal for';
    } else if (this.state.transactionType === 'Transfer') {
      foreignLabel = 'Transfer to';
    }

    body = (
      <div>
        <h3>
          Edit the transaction for the '{this.state.transactionAccountName}'
          {' '}account
        </h3>
        <div
          className='loginFormWrapper'>
          <Form
            className='loginForm'
            fluid='true'>
            <FormGroup
              controlId="loginError"
              validationState='error'
              hidden={!this.state.transactionUpdateErrorText}>
              <ControlLabel>
                {this.state.transactionUpdateErrorText}
              </ControlLabel>
            </FormGroup>
            <FormGroup
              controlId="formType">
              <ControlLabel>Type</ControlLabel>
              <FormControl
                bsSize='large'
                onChange={this.validationUpdateForm.bind(this)}
                disabled={this.state.transactionUpdateInProgress
                  || this.state.transactionDeleteInProgress}
                inputRef={(ref) => {this.type = ref}}
                type='text' value={this.state.transactionType}
                readOnly/>
            </FormGroup>
            <FormGroup
              controlId="formDate">
              <ControlLabel>Date</ControlLabel>
              <FormControl
                bsSize='large'
                disabled={this.state.transactionUpdateInProgress
                  || this.state.transactionDeleteInProgress}
                inputRef={(ref) => {this.date = ref}}
                type='datetime-local'
                value={this.state.transactionDate}
                readOnly/>
            </FormGroup>
            <FormGroup
              controlId="amountError"
              validationState={this.state.amountValid ? 'success' : 'error'}>
                <ControlLabel
                  hidden={this.state.amountValid}>
                  {this.state.amountErrorText}
                </ControlLabel>
            </FormGroup>
            <FormGroup
              controlId="formAmount"
              validationState={this.state.amountValid
                && this.state.transactionChanged ? 'success' : 'error'}>
                <ControlLabel>Amount</ControlLabel>
                <FormControl
                  type='number'
                  step='0.00'
                  bsSize='large'
                  onChange={this.validationUpdateForm.bind(this)}
                  disabled={this.state.transactionUpdateInProgress
                    || this.state.transactionDeleteInProgress}
                  inputRef={(ref) => {this.amount = ref}}
                  defaultValue={Math.abs(this.state.transactionAmount)
                    .toFixed(2)}/>
            </FormGroup>
            <FormGroup
              controlId="formCategoryId"
              disabled={this.state.transactionUpdateInProgress
                || this.state.transactionDeleteInProgress}
              validationState={this.state.transactionChanged
                ? 'success'
                : 'error'}>
              <ControlLabel>Category</ControlLabel>
              {this.getCategories()}
            </FormGroup>
            <FormGroup
              controlId="formForeignId"
              hidden={this.state.transactionType === 'Transfer'}
              disabled={this.state.transactionType === 'Transfer'
                || this.state.transactionUpdateInProgress
                || this.state.transactionDeleteInProgress}
              validationState={this.state.transactionChanged
                ? 'success'
                : 'error'}>
              <ControlLabel>{foreignLabel}</ControlLabel>
              {this.getForeigns()}
            </FormGroup>
            <FormGroup>
              <LinkContainer
                to={accountLink}>
                <Button
                  disabled={this.state.transactionUpdateInProgress
                    || this.state.transactionDeleteInProgress}>
                   Back
                </Button>
              </LinkContainer>
              <ButtonToolbar
                className='pull-right'>
                <Button
                  bsStyle='danger'
                  onClick={(e) => {
                    if (window.confirm(
                      'Are you sure? This action cannot be undone.'
                    )) {
                      this.deleteTransaction(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  disabled={!this.state.amountValid
                    || this.state.transactionUpdateInProgress
                    || this.state.transactionDeleteInProgress}>
                  {this.state.transactionDeleteInProgress
                    ? 'Deleting transaction...'
                    : 'Delete'}
                </Button>
                <Button
                  bsStyle='success'
                  className='loginButton'
                  disabled={!(this.state.amountValid
                    && this.state.transactionChanged)
                    || this.state.transactionUpdateInProgress
                    || this.state.transactionDeleteInProgress}
                  type="submit" onClick={this.updateTransaction.bind(this)}>
                  {this.state.transactionUpdateInProgress
                    ? 'Updating transaction...'
                    : 'Update'}
                </Button>
              </ButtonToolbar>
            </FormGroup>
          </Form>
        </div>
        <Modal
          dialogComponentClass='modal'
          show={this.state.transactionUpdateInProgress
            || this.state.transactionDeleteInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </div>
    );

    return body;
  }

  render() {
    return (
      this.getBody()
    );
  }
}
