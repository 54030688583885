import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class AddPayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payerInsertInProgress: false,
      payerNameValid: false,
      payerAddErrorText: null
    };
  }

  validateAddForm() {
    var nameValid;

    if (this.name.value !== null && this.name.value.length > 0
          && this.name.value.length <= 20) {
      nameValid = true;
    } else {
      nameValid = false;
    }

    this.setState({
      payerNameValid: nameValid
    });
  }

  addPayer(e) {
    e.preventDefault();
    this.setState({
      payerInsertInProgress: true
    }, () => {
      $.ajax({
        method: 'POST',
        url: API_ROUTE + '/payer/',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        data: JSON.stringify({
          name: this.name.value
        })
      }).done((data) => {
        this.name.value = '';
        this.setState({payerAddErrorText: null});
        this.props.getPayers();
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          payerAddErrorText: errorString
        });
      }).always(() => {
        this.setState({
          payerInsertInProgress: false,
          payerNameValid: false
        });
      });
    });
  }

  render() {
    return (
      <Form
        className='settingsForm'
        fluid='true'>
        <FormGroup
          controlId="addError"
          validationState='error'
          hidden={!this.state.payerAddErrorText}>
          <ControlLabel>{this.state.payerAddErrorText}</ControlLabel>
        </FormGroup>
        <FormGroup
          controlId="formName"
          validationState={this.state.payerNameValid ? 'success' : 'error'}>
          <ControlLabel>Add payer</ControlLabel>
          <FormControl
            bsSize='large'
            onChange={this.validateAddForm.bind(this)}
            disabled={this.state.payerInsertInProgress}
            inputRef={(ref) => {this.name = ref}}
            type='text'
            placeholder='New payer name'>
          </FormControl>
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='success'
              className='loginButton'
              disabled={this.state.payerInsertInProgress
                || !this.state.payerNameValid}
              type="submit"
              onClick={this.addPayer.bind(this)}>
              {this.state.payerInsertInProgress ? 'Adding payer...' : 'Add'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.payerInsertInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
