import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import '../App.css';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginCallInProgress: false,
      loginPasswordValid: false,
      loginUsernameValid: false,
      loginErrorText: null,
    };
  }

  validateLoginForm() {
    var usernameGood, passwordGood;

    if (!!this.username.value != null
          && this.username.value
              .match(/^[A-z.\-_0-9]+@[A-z.\-_0-9]+\.[A-z]{2,5}$/)
          && this.username.value.length <= 450) {
      usernameGood = true;
    } else {
      usernameGood = false;
    }
    if (!!this.password.value != null && this.password.value.length >= 8
          && this.password.value.length <= 40) {
      passwordGood = true;
    } else {
      passwordGood = false;
    }
    this.setState({
      loginUsernameValid: usernameGood,
      loginPasswordValid: passwordGood
    });
  }

  login(e) {
    e.preventDefault();
    try {
      var username = this.username.value;
      var password = this.password.value;
      this.setState({loginCallInProgress: true}, () => {
        $.ajax({
          method: 'GET',
          url: API_ROUTE + '/login/'
            + username.toLowerCase(),
          dataType: 'json',
          contentType: 'application/json; charset=utf-8;',
          cache: false,
          crossOrigin: true,
          headers: {
            x_cba_password: btoa(password),
            "access-control-allow-origin": API_ROUTE,
          }
        }).done((data) => {
          this.setState({
            loginErrorText: null,
            loginCallInProgress: false
          }, () => {
            this.props.setUser(data);
          });
        }).fail((data) => {
          var errorString;
          if (data.status === 0 || !data.responseText) {
            errorString = 'Web application down. Please try again later.'
          } else {
            errorString = data.responseText.replace(/"/g, '').replace('.', '');
          }
          this.setState({
            loginErrorText: errorString,
            loginCallInProgress: false,
          });
        });
      });
    } catch (e) {
      console.log(e);
      this.setState({
        loginErrorText: 'Web application down. Please try again later.',
        loginCallInProgress: false,
      });
    }
  }

  render() {
    return (
      <Form
        className='loginForm'
        fluid='true'>
        <FormGroup
          controlId="loginError"
          validationState='error'
          hidden={!this.state.loginErrorText}>
          <ControlLabel>{this.state.loginErrorText}</ControlLabel>
        </FormGroup>
        <FormGroup
          controlId="formEmail"
          validationState={this.state.loginUsernameValid ? 'success' : 'error'}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            bsSize='large'
            onChange={this.validateLoginForm.bind(this)}
            disabled={this.state.loginCallInProgress}
            autoComplete='email'
            inputRef={(ref) => {this.username = ref}}
            type='email'
            placeholder='Email'/>
        </FormGroup>
        <FormGroup
          controlId="formPassword"
          validationState={this.state.loginPasswordValid ? 'success' : 'error'}>
            <ControlLabel>Password</ControlLabel>
            <FormControl
              bsSize='large'
              onChange={this.validateLoginForm.bind(this)}
              disabled={this.state.loginCallInProgress}
              autoComplete='current-password'
              inputRef={(ref) => {this.password = ref}}
              type='password'
              placeholder='Password'/>
        </FormGroup>
        <FormGroup>
          <Button
            onClick={() => this.props.goToRegisterPage()}
            disabled={this.state.loginCallInProgress}>
             Register
          </Button>
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='success'
              disabled={!this.state.loginPasswordValid
                || !this.state.loginUsernameValid
                || this.state.loginCallInProgress}
              type="submit"
              onClick={this.login.bind(this)}>
              {this.state.loginCallInProgress ? 'Logging in...' : 'Log in'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.loginCallInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
