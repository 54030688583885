import React, {Component} from 'react';
import AddAccount from './addAccount';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class BankPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankId: this.props.match.params.bankId,
      bank: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.bankId !== this.props.match.params.bankId) {
      this.setState({bank: null}, () => {
        $.ajax({
          method: 'GET',
          url: API_ROUTE + '/bank/'
            + nextProps.match.params.bankId,
          headers: {
            x_cba_apikey: nextProps.user.apiKey,
            "access-control-allow-origin": API_ROUTE,
          },
          dataType: 'json',
          cache: false,
          crossOrigin: true,
          contentType: 'application/json; charset=utf-8;'
        })
        .done((data) => {
          console.log(data);
          this.setState({
            bank: data,
            bankId: nextProps.match.params.bankId
          });
        })
        .fail((data) => {
          console.log(data.responseText);
        });
      });
    }
  }

  componentWillMount() {
    $.ajax({
     method: 'GET',
     url: API_ROUTE + '/bank/' + this.state.bankId,
     headers: {
       x_cba_apikey: this.props.user.apiKey,
       "access-control-allow-origin": API_ROUTE,
     },
     dataType: 'json',
     cache: false,
     crossOrigin: true,
     contentType: 'application/json; charset=utf-8;'
   })
   .done((data) => {
     this.setState({bank: data});
   })
   .fail((data) => {
     console.log(data.responseText);
   });
  }

  getBody() {
    var body;

    if (this.state.bank) {
      body = (
        <AddAccount
          user={this.props.user}
          getAccounts={this.props.getAccounts}
          history={this.props.history}
          bank={this.state.bank}/>
      );
    } else {
      body = (
        <div>
          <h2>Loading bank information...</h2>
        </div>
      );
    }

    return body;
  }

  render() {
    return (
      this.getBody()
    );
  }
}
