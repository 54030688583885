import React, {Component} from 'react';
import AddBank from './addBank';
import AddCategory from './addCategory';
import AddPayee from './addPayee';
import AddPayer from './addPayer';

export default class HomePage extends Component {
  render () {
    return (
      <div>
        <h1>Hi {this.props.user.name}!</h1>
        <p>
          Use the top navigation bar to view your accounts and transactions.
          <br/>
        </p>
        <p>
          If you need to remove any transaction entities or edit your profile,
          go to the <b>Settings</b> tab.
          <br/>
          Use the below forms to add new transaction entities.
        </p>
        <hr/>
        <AddBank
          user={this.props.user}
          getBanks={this.props.getBanks}/>
        <AddCategory
          user={this.props.user}
          getCategories={this.props.getCategories}/>
        <AddPayee
          user={this.props.user}
          getPayees={this.props.getPayees}/>
        <AddPayer
          user={this.props.user}
          getPayers={this.props.getPayers}/>
      </div>
    );
  }
}
