import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import * as TransactionTypeHelper from '../classes/TransactionTypeHelper';
import { getCurrentUTCDateISO } from '../classes/TransactionHelper';
import { API_ROUTE } from '../index';

const $ = require('jquery');

export default class AddTransaction extends Component {
  constructor(props) {
    super(props);    
    
    var startForeignValue = this.getForeignDefault(TransactionTypeHelper.TransactionTypeEnum.valueOf(TransactionTypeHelper.TransactionTypeEnum.DEPOSIT));
    this.state = {
      addTransactionInProgress: false,
      addTransactionErrorText: null,
      transactionAmountValid: false,
      transactionType: TransactionTypeHelper.TransactionTypeEnum.valueOf(TransactionTypeHelper.TransactionTypeEnum.DEPOSIT),      
      foreignValue: startForeignValue,
      categoryValue: this.props.categoryNoneId
    };       
  }

  validateAddTransactionForm() {
    var amount;

    if (!!this.amount.value
      && this.amount.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)
      && this.amount.value > 0) {
      amount = true;
    } else {
      amount = false;
    }

    this.setState({
      transactionAmountValid: amount
    });
  }

  changeTransactionFormType(value) {
    var type = TransactionTypeHelper.TransactionTypeEnum.valueOf(value.target.value);

    if (TransactionTypeHelper.TransactionTypeEnum.valueOf(this.state.transactionType) !== type) {   
      var foreignValue = Number(this.getForeignDefault(type));         
    
      this.setState({
        transactionType: type,
        addTransactionErrorText: null,        
        foreignValue: foreignValue
      });   
    }
  }  

  getForeignDefault(transactionType) {
    switch (TransactionTypeHelper.TransactionTypeEnum.valueOf(transactionType)) {
      case TransactionTypeHelper.TransactionTypeEnum.DEPOSIT:
        return this.props.payerNoneId;
      case TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL:
        return this.props.payeeNoneId;
      case TransactionTypeHelper.TransactionTypeEnum.TRANSFER:
        return this.findNeighborAccount(this.props.accounts, this.props.account);
      default:
        throw new Error('Invalid transaction type');
    }
  }  

  findNeighborAccount(accounts, account) {
    var accountId = account.id;
    var returnId = null;
    var keys = [...accounts.keys()];

    for (var i = 0; i < keys.length; i++) {     
      var id = keys[i];
      if (id !== accountId) {
        returnId = id;
        break;
      }
    }

    return returnId;
  }

  addTransaction(e) {
    e.preventDefault();
    var utcDate = getCurrentUTCDateISO();
    this.setState({
      addTransactionInProgress: true
    }, () => {
      var url;
      var transactionType = TransactionTypeHelper.TransactionTypeEnum.valueOf(this.state.transactionType);
      
      switch (transactionType) {
        case TransactionTypeHelper.TransactionTypeEnum.DEPOSIT:
          url = API_ROUTE + '/deposit/';      
          break;
        case TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL:
          url = API_ROUTE + '/withdrawal/';
          break;
        case TransactionTypeHelper.TransactionTypeEnum.TRANSFER:
          url = API_ROUTE + '/transfer/';        
          break;
        default:
          throw new Error('Invalid transaction type');
      }

      $.ajax({
        method: 'POST',
        url: url,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        data: JSON.stringify({
          accountId: this.props.account.id,
          date: utcDate,
          amount: this.amount.value,
          categoryId: this.state.categoryValue,
          foreignId: this.state.foreignValue
        })
      }).done((data) => {
        this.resetForm(transactionType);
      }).fail((data) => {
        this.handleAPIError(data);
      }).always(() => {
        this.finalizeAPICall();
      });
    });
  }

  resetForm(transactionType) {
    var foreignId = this.getForeignDefault(transactionType);

    this.amount.value = '';
    ReactDOM.findDOMNode(this.categorySelect).value = this.catNoneId;
    ReactDOM.findDOMNode(this.foreignSelect).value = foreignId;
    this.props.getSQLData();
  }

  setForeignValue(e) {
    e.preventDefault();
    this.setState({
      foreignValue: Number(e.target.value)
    });
  }

  setCategoryValue(e) {
    e.preventDefault();
    this.setState({
      categoryValue: Number(e.target.value)
    });
  }

  handleAPIError(data) {
    var errorString;

    if (data.status === 0 || !data.responseText) {
      errorString = 'Web application down. Please try again later.'
    } else {
      errorString = data.responseText.replace(/"/g, '').replace('.', '');
    }

    this.setState({
      addTransactionErrorText: errorString
    });
  }

  finalizeAPICall() {
    this.setState({
      addTransactionInProgress: false
    });
  }  

  getCategories() {
    var dropdown;
    var categories = [];

    this.props.categories.forEach((value, key) => {
      if (value.name === 'None') {          
        this.catNoneId = key;
      }
      categories.push(
        <option
          value={value.id}
          key={value.id + 'category'}>
          {value.name}
        </option>
      );
    });

    dropdown = (
      <FormControl
        componentClass='select'
        placeholder='categorySelect'
        bsSize='large'
        disabled={this.state.transactionUpdateInProgress}        
        ref={select => { this.categorySelect = select }}
        value={this.state.categoryValue}
        onChange={e => this.setCategoryValue(e)}>
        {categories}
      </FormControl>
    );

    return dropdown;
  }

  getForeigns() {
    var foreigns = [];
    var dropdown;
    var foreign;    
    var transactionType = TransactionTypeHelper.TransactionTypeEnum.valueOf(this.state.transactionType);

    // determine who the possible places/people the transaction was for/from
    switch (transactionType) {
      case TransactionTypeHelper.TransactionTypeEnum.DEPOSIT:
        foreign = this.props.payers;
        break;
      case TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL:
        foreign = this.props.payees;
        break;
      case TransactionTypeHelper.TransactionTypeEnum.TRANSFER:
        foreign = this.props.accounts;
        break;
      default:
        throw new Error('Invalid transaction type');
    }

    // find default value
    foreign.forEach((value, key) => {
      var add = true;
      var name = TransactionTypeHelper.SpecialCategoryEnum.valueOf(value.name);
      var transactionType = TransactionTypeHelper.TransactionTypeEnum.valueOf(this.state.transactionType);

      switch (transactionType) {
        case TransactionTypeHelper.TransactionTypeEnum.DEPOSIT:
          if (name === TransactionTypeHelper.SpecialCategoryEnum.NONE) {              
            this.payerNoneId = key;     
          }
          break;
        case TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL:
          if (name === TransactionTypeHelper.SpecialCategoryEnum.NONE) {                               
            this.payeeNoneId = key;
          }
          break;
        case TransactionTypeHelper.TransactionTypeEnum.TRANSFER:          
          if (value.id === this.props.account.id) {
            add = false;
          }
          break;
        default:
          throw new Error('Invalid transaction type');
      }

      if (add) {
        foreigns.push(
          <option
            value={value.id}
            key={value.id + 'foreign'}>
            {value.name}
          </option>
        );
      }
    });     

    dropdown = (
      <FormControl
        componentClass='select'
        placeholder='categorySelect'
        bsSize='large'
        disabled={this.state.transactionUpdateInProgress}        
        ref={select => { this.foreignSelect = select }}
        value={this.state.foreignValue}
        onChange={e => this.setForeignValue(e)}>
        {foreigns}
      </FormControl>
    );

    return dropdown;
  }

  getBody() {
    var body;
    var foreignLabel;

    if (this.state.transactionType === TransactionTypeHelper.TransactionTypeEnum.DEPOSIT) {
      foreignLabel = 'Deposit from';
    } else if (this.state.transactionType === TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL) {
      foreignLabel = 'Withdrawal for';
    } else if (this.state.transactionType === TransactionTypeHelper.TransactionTypeEnum.TRANSFER) {
      foreignLabel = 'Transfer to';
    }

    body = (
      <Form
        className='settingsForm'
        fluid='true'>
        <FormGroup
          controlId="loginError"
          validationState='error'
          hidden={!this.state.addTransactionErrorText}>
          <ControlLabel>{this.state.addTransactionErrorText}</ControlLabel>
        </FormGroup>
        <FormGroup
          controlId='formType'>
          <ControlLabel>Type</ControlLabel>
          <FormControl
            componentClass='select'
            placeholder='typeSelect'
            bsSize='large'
            onChange={this.changeTransactionFormType.bind(this)}>
            <option
              value={TransactionTypeHelper.TransactionTypeEnum.DEPOSIT}
              key='depositSelect'>
              {TransactionTypeHelper.TransactionTypeEnum.DEPOSIT}
            </option>
            <option
              value={TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL}
              key='withdrawalSelect'>
              {TransactionTypeHelper.TransactionTypeEnum.WITHDRAWAL}
            </option>
            <option
              value={TransactionTypeHelper.TransactionTypeEnum.TRANSFER}
              key='transferSelect'>
              {TransactionTypeHelper.TransactionTypeEnum.TRANSFER}
            </option>
          </FormControl>
        </FormGroup>
        <FormGroup
          controlId="formAmount"
          validationState={this.state.transactionAmountValid
            ? 'success'
            : 'error'}>
            <ControlLabel>Amount</ControlLabel>
            <FormControl
              type='number'
              step='0.00'
              bsSize='large'
              onChange={this.validateAddTransactionForm.bind(this)}
              disabled={this.state.addTransactionInProgress}
              autoComplete='off'
              inputRef={(ref) => {this.amount = ref}}
              placeholder='Transaction amount'/>
        </FormGroup>
        <FormGroup
          controlId="formCategoryId">
          <ControlLabel>Category</ControlLabel>
          {this.getCategories()}
        </FormGroup>
        <FormGroup
          controlId="formCategoryId">
          <ControlLabel>{foreignLabel}</ControlLabel>
          {this.getForeigns()}
        </FormGroup>
        <FormGroup>
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='success'
              className='loginButton'
              disabled={!this.state.transactionAmountValid
                || this.state.addTransactionInProgress}
              type="submit"
              onClick={this.addTransaction.bind(this)}>
              {this.state.addTransactionInProgress
                ? 'Adding transaction...'
                : 'Add Transaction'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.addTransactionInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );

    return body;
  }

  render() {
    return (
      this.getBody()
    );
  }
}
