import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';

export default class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameUpdateInProgress: false,
      passwordUpdateInProgress: false,
      nameValid: false,
      passwordValid: false,
      passwordMatch: false
    }
  }

  validateNameForm() {
    var nameValid;

    if (!!this.name.value && this.name.value.length > 0
      && this.name.value !== this.props.user.name) {
      nameValid = true;
    } else {
      nameValid = false;
    }

    this.setState({nameValid: nameValid});
  }

  validatePasswordForm() {
    var passwordValid;
    var passwordMatch;

    if (!!this.password.value != null && this.password.value.length >= 8
      && this.password.value
        .match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
      passwordValid = true;
    } else {
      passwordValid = false;
    }
    if (this.password.value === this.passwordMatch.value
      && this.password.value.length >= 8) {
      passwordMatch = true;
    } else {
      passwordMatch = false;
    }

    this.setState({
      passwordValid: passwordValid,
      passwordMatch: passwordMatch
    });
  }

  updateUserName(e) {
    e.preventDefault();
    this.setState({
      nameUpdateInProgress: true
    }, () => {
      setTimeout(() => {
        this.setState({
          nameUpdateInProgress: false, nameValid: false
        });
      }, 2000);
    });
  }

  updateUserPassword(e) {
    e.preventDefault();
    this.setState({
      passwordUpdateInProgress: true
    }, () => {
      setTimeout(() => {
        this.setState({
          passwordUpdateInProgress: false, passwordValid: false
        });
        this.password.value = this.passwordMatch.value = '';
      }, 2000);
    });
  }

  getNameUpdateForm() {
    return (
      <Form
        className='settingsForm'
        fluid='true'
        autoComplete='off'>
        <FormGroup
          controlId="formName"
          validationState={this.state.nameValid ? 'success' : 'error'}>
          <ControlLabel>Change user name</ControlLabel>
          <FormControl
            bsSize='large'
            autoComplete='off'
            onChange={this.validateNameForm.bind(this)}
            disabled={this.state.nameUpdateInProgress}
            inputRef={(ref) => {this.name = ref}}
            type='text'
            defaultValue={this.props.user.name}/>
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='success'
              className='loginButton'
              disabled={!this.state.nameValid
                || this.state.nameUpdateInProgress}
              type="submit" onClick={this.updateUserName.bind(this)}>
              {this.state.nameUpdateInProgress ? 'Updating...' : 'Update'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }

  getPasswordUpdateForm() {
    return (
      <Form
        className='settingsForm'
        fluid='true'
        autoComplete='off'>
        <FormGroup
          controlId="formPassword"
          validationState={this.state.passwordValid ? 'success' : 'error'}>
          <ControlLabel>Change user password</ControlLabel>
          <FormControl
            bsSize='large'
            autoComplete='off'
            onChange={this.validatePasswordForm.bind(this)}
            disabled={this.state.passwordUpdateInProgress}
            inputRef={(ref) => {this.password = ref}}
            type='password'
            placeholder='Password'/>
        </FormGroup>
        <FormGroup
          controlId="formMatch"
          validationState={this.state.passwordMatch ? 'success' : 'error'}>
          <FormControl
            bsSize='large'
            autoComplete='off'
            onChange={this.validatePasswordForm.bind(this)}
            disabled={this.state.passwordUpdateInProgress}
            inputRef={(ref) => {this.passwordMatch = ref}}
            type='password'
            placeholder='Verify password'/>
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='success'
              className='loginButton'
              disabled={!this.state.passwordValid || !this.state.passwordMatch
                || this.state.passwordUpdateInProgress}
              type="submit"
              onClick={this.updateUserPassword.bind(this)}>
              {this.state.passwordUpdateInProgress ? 'Updating...' : 'Update'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.nameUpdateInProgress
            || this.state.passwordUpdateInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }

  render() {
    return (
      <div>
        {this.getNameUpdateForm()}
        {this.getPasswordUpdateForm()}
      </div>
    );
  }
}
