import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import EditUser from './editUser';
import DeleteBank from './deleteBank';
import DeleteCategory from './deleteCategory';
import DeletePayee from './deletePayee';
import DeletePayer from './deletePayer';
import DeleteUser from './deleteUser'
import '../App.css';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class SettingsPage extends Component {
  resetCache(e) {
    e.preventDefault();
    this.setState({apiCallInProgress: true});

    $.ajax({
      method: 'PUT',
      url: API_ROUTE + '/util/clearCaches/forUser',
      headers: {
        x_cba_apikey: this.props.user.apiKey,
        x_cba_pin: 1490,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      this.props.refreshUserData();
    })
    .fail((data) => {
      console.log(data.responseText);
    });
  }

  render() {
    return (
      <div>
        <h1>Profile settings</h1>
        <Button
          bsStyle='success'
          onClick={(e) => this.resetCache(e)}>
          Clear Caches
        </Button>
        <hr/>
        <EditUser
          user={this.props.user}/>
        <hr/>
        <h4>All deletions made <b>cannot</b> be undone.</h4>
        <DeleteBank
          banks={this.props.banks}
          user={this.props.user}
          getBanks={this.props.getBanks}/>
        <DeleteCategory
          categories={this.props.categories}
          user={this.props.user}
          getCategories={this.props.getCategories}/>
        <DeletePayee
          payees={this.props.payees}
          user={this.props.user}
          getPayees={this.props.getPayees}/>
        <DeletePayer
          payers={this.props.payers}
          user={this.props.user}
          getPayers={this.props.getPayers}/>
        <hr/>
        <h4>
          Deleting the user will also remove all accounts, banks, and
          {' '}transactions. This cannot be undone.
        </h4>
        <DeleteUser
          user={this.props.user}
          history={this.props.history}
          setUser={this.props.setUser}/>
      </div>
    );
  }
}
