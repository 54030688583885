import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class DeleteBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankDeleteInProgress: false,
      bankDeleteErrorText: null
    };
  }

  deleteBank(e) {
    e.preventDefault();
    this.setState({
      bankDeleteInProgress: true
    }, () => {
      $.ajax({
        method: 'DELETE',
        url: API_ROUTE + '/bank/'
          + ReactDOM.findDOMNode(this.bankSelect).value,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        }
      }).done((data) => {
          this.props.getBanks();
          this.setState({bankDeleteErrorText: null});
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          bankDeleteErrorText: errorString
        });
        setTimeout(() => this.setState({bankDeleteErrorText: null}), 5000);
      }).always(() => {
        this.setState({bankDeleteInProgress: false});
      });
    });
  }

  getBanks() {
    var dropdown;
    var banks = [];

    this.props.banks.forEach((value, key) => {
      banks.push(
        <option
          value={key}
            key={key + 'bank'}>
          {value.name}
        </option>
      );
    });

    dropdown = (
      <FormControl
        componentClass='select'
        placeholder='bankSelect'
        bsSize='large'
        disabled={this.state.bankDeleteInProgress}
        defaultValue=''
        ref={select => { this.bankSelect = select }}>
        {banks}
      </FormControl>
    );

    return dropdown;
  }

  render() {
    return (
      <Form
        className='settingsForm'
        fluid='true'>
        <FormGroup
          controlId="deleteError"
          validationState='error'
          hidden={!this.state.bankDeleteErrorText}>
          <ControlLabel>{this.state.bankDeleteErrorText}</ControlLabel>
        </FormGroup>
        <FormGroup
          controlId="formBankId">
          <ControlLabel>Delete banks</ControlLabel>
          {this.getBanks()}
          <ButtonToolbar
            className='pull-right'>
            <Button
              bsStyle='danger'
              className='loginButton'
              disabled={this.state.bankDeleteInProgress}
              type="submit"
              onClick={(e) => {
                if (window.confirm(
                  'Are you sure? This action cannot be undone.'
                )) {
                  this.deleteBank(e);
                } else {
                  e.preventDefault();
                }
              }}>
              {this.state.bankDeleteInProgress ? 'Deleting bank...' : 'Delete'}
            </Button>
          </ButtonToolbar>
        </FormGroup>
        <Modal
          dialogComponentClass='modal'
          show={this.state.bankDeleteInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </Form>
    );
  }
}
