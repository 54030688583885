import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {
  Form, FormGroup, Button, FormControl, ControlLabel, ButtonToolbar
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import QueryParser from '../classes/QueryParser';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class EditAccount extends Component {
  constructor(props) {
    super(props);
    var query = new QueryParser(this.props.location.search);
    this.state = {
      accountId: query.get('id'),
      accountName: query.get('name'),
      accountLastFour: query.get('lastFour'),
      accountUpdateInProgress: false,
      accountDeleteInProgress: false,
      accountNameValid: false,
      accountLastFourValid: false,
      accountChanged: false,
      accountUpdateErrorText: null
    };
  }

  validateUpdateForm() {
    var nameValid;
    var lastFourValid;
    var changed;

    if (this.name.value !== null && this.name.value.length > 0
          && this.name.value.length <= 20) {
      nameValid = true;
    } else {
      nameValid = false;
    }
    if (this.lastFour.value === null || this.lastFour.value === ''
          || this.lastFour.value.match(/^[0-9]{4}$/)) {
      lastFourValid = true;
    } else {
      lastFourValid = false;
    }
    if (this.name.value === this.state.accountName
          && this.lastFour.value === this.state.accountLastFour) {
      changed = false;
    } else {
      changed = true;
    }

    this.setState({
      accountNameValid: nameValid,
      accountLastFourValid: lastFourValid,
      accountChanged: changed
    });
  }

  deleteAccount(e) {
    e.preventDefault();
    this.setState({
      accountDeleteInProgress: true
    }, () => {
      $.ajax({
        method: 'DELETE',
        url: API_ROUTE + '/account/'
          + this.state.accountId,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        }
      }).done((data) => {
        this.setState({
          accountUpdateErrorText: null,
          accountDeleteInProgress: false
        }, () => {
          this.props.getAccounts();
          this.props.history.push('/');
        });
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          accountUpdateErrorText: errorString,
          accountDeleteInProgress: false
        });
      });
    });
  }

  updateAccount(e) {
    e.preventDefault();
    this.setState({
      accountUpdateInProgress: true
    }, () => {
      $.ajax({
        method: 'PUT',
        url: API_ROUTE + '/account/'
          + this.state.accountId,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8;',
        cache: false,
        crossOrigin: true,
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        data: JSON.stringify({
          name: this.name.value,
          lastFourDigits: this.lastFour.value
        })
      }).done((data) => {
        this.setState({
          accountUpdateErrorText: null,
          accountUpdateInProgress: false
        }, () => {
          this.props.getAccounts();
          this.props.history.push('/account/' + this.state.accountId);
        });
      }).fail((data) => {
        var errorString;
        if (data.status === 0 || !data.responseText) {
          errorString = 'Web application down. Please try again later.'
        } else {
          errorString = data.responseText.replace(/"/g, '').replace('.', '');
        }
        this.setState({
          accountUpdateErrorText: errorString,
          accountUpdateInProgress: false
        });
      });
    });
  }

  render() {
    return (
      <div>
        <h3>
          Edit the '{this.state.accountName}' account
        </h3>
        <div
          className='loginFormWrapper'>
          <Form
            className='loginForm'
            fluid='true'>
            <FormGroup
              controlId="loginError"
              validationState='error'
              hidden={!this.state.accountUpdateErrorText}>
              <ControlLabel>{this.state.accountUpdateErrorText}</ControlLabel>
            </FormGroup>
            <FormGroup
              controlId="formName"
              validationState={this.state.accountNameValid
                && this.state.accountChanged ? 'success' : 'error'}>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                bsSize='large'
                onChange={this.validateUpdateForm.bind(this)}
                disabled={this.state.accountUpdateInProgress
                  || this.state.accountDeleteInProgress}
                inputRef={(ref) => {this.name = ref}}
                type='text' defaultValue={this.state.accountName}/>
            </FormGroup>
            <FormGroup
              controlId="formLastFour"
              validationState={this.state.accountLastFourValid
                && this.state.accountChanged ? 'success' : 'error'}>
              <ControlLabel>Last Four Digits (Optional)</ControlLabel>
              <FormControl
                type='number'
                step='0'
                bsSize='large'
                onChange={this.validateUpdateForm.bind(this)}
                disabled={this.state.accountUpdateInProgress
                  || this.state.accountDeleteInProgress}
                inputRef={(ref) => {this.lastFour = ref}}
                defaultValue={this.state.accountLastFour}
                placeholder='Last four digits of account number'/>
            </FormGroup>
            <FormGroup>
              <LinkContainer
                to={'/account/' + this.state.accountId}>
                <Button
                  disabled={this.state.accountUpdateInProgress
                  || this.state.accountDeleteInProgress}>
                   Back
                </Button>
              </LinkContainer>
              <ButtonToolbar
                className='pull-right'>
                <Button
                  bsStyle='danger'
                  onClick={(e) => {
                    if (window.confirm(
                      'Are you sure? This action cannot be undone.'
                    )) {
                      this.deleteAccount(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  disabled={this.state.accountUpdateInProgress
                    || this.state.accountDeleteInProgress}>
                  {this.state.accountDeleteInProgress
                    ? 'Deleting account...'
                    : 'Delete'}
                </Button>
                <Button
                  bsStyle='success'
                  className='loginButton'
                  disabled={!(this.state.accountNameValid
                    && this.state.accountLastFourValid
                    && this.state.accountChanged)
                    || this.state.accountUpdateInProgress
                    || this.state.accountDeleteInProgress}
                  type="submit"
                  onClick={this.updateAccount.bind(this)}>
                  {this.state.accountUpdateInProgress
                    ? 'Updating account...'
                    : 'Update'}
                </Button>
              </ButtonToolbar>
            </FormGroup>
          </Form>
        </div>
        <Modal
          dialogComponentClass='modal'
          show={this.state.accountDeleteInProgress
            || this.state.accountUpdateInProgress}>
          <Loader color="#FFF"/>
        </Modal>
      </div>
    );
  }
}
