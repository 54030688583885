import React, {Component} from 'react';
import {Table, Modal, Button} from 'react-bootstrap';
import Loader from 'react-loader';
import QueryParser from '../classes/QueryParser';
import ColorPicker from '../classes/ColorPicker';
import Chart from 'chart.js/auto';
import PageActionEnum from '../classes/PageActionEnum';
import {TransactionTypeEnum} from '../classes/TransactionTypeHelper';
import * as TransactionHelper from '../classes/TransactionHelper';
import { API_ROUTE } from '../index';

const $ = require('jquery');
const CP = new ColorPicker();

export default class ReportPage extends Component {
  constructor(props) {    
    super(props);
    var query = new QueryParser(this.props.location.search);  
    this.state = {
      startDate: query.get('startDate'),
      endDate: query.get('endDate'),
      minAmount: query.get('minAmount'),
      maxAmount: query.get('maxAmount'),
      type: TransactionTypeEnum.valueOf(query.get('type')),
      categoryId: query.get('categoryId'),
      foreignId: query.get('foreignId'),
      bankId: query.get('bankId'),
      accountId: query.get('accountId'),
      transactions: null,
      depositAmount: 0,
      withdrawalAmount: 0,
      transactionTotal: 0,
      loadingTransactions: false,
      transactionStartIndex: 0,
      transactionListSize: 10
    };
    this.transactionPageInterval = null;
  }

  componentWillMount() {
    this.setState({loadingTransactions: true}, () => {
      var accParam = this.state.accountId ? ('/' + this.state.accountId) : '';
      var queryString =
        (this.state.type === TransactionTypeEnum.ALL ? '' : '&type=' + this.state.type) +
        '&minAmount=' + this.state.minAmount +
        '&maxAmount=' + this.state.maxAmount +
        '&startDate=' + this.state.startDate +
        '&endDate=' + this.state.endDate +
        (this.state.foreignId ? ('&foreignId=' + this.state.foreignId) : '') +
        (this.state.categoryId ? ('&categoryId=' + this.state.categoryId) : '') +
        (this.state.accountId ? ('&accountId=' + this.state.accountId) : '') +
        (this.state.bankId ? ('&bankId=' + this.state.bankId) : '');
      queryString = '?' + queryString.substr(1);
      var apiUrl = API_ROUTE + '/transaction' + accParam
        + queryString

      $.ajax({
        method: 'GET',
        url: apiUrl,
        crossOrigin: true,
        dataType: 'json',
        cache: false,
        contentType: 'application/json; charset=utf-8;',
        headers: {
          x_cba_apikey: this.props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        }
      }).done((data) => {
        if (data) {
          this.setState({
            transactions: data.transactions, 
            depositAmount: TransactionHelper.formatCurrency(data.depositTotal),
            withdrawalAmount: TransactionHelper.formatCurrency(data.withdrawalTotal),
            transactionTotal: TransactionHelper.formatCurrency(data.transactionTotal),
            loadingTransactions: false
          });
        } else {
          this.setState({transactions: [], loadingTransactions: false});
        }
      }).fail((data) => {
        this.setState({transactions: [], loadingTransactions: false});
      });
    });
  }

  componentDidUpdate() {
    if (this.state.transactions && this.state.transactions.length > 0) {
      this.loadChart();
    }
  }

  nextPageFunc() {
    this.setState({
      transactionStartIndex: this.state.transactionStartIndex
        + this.state.transactionListSize
    }, () => {
      if (this.state.transactionStartIndex + this.state.transactionListSize >= this.state.transactions.length) {
        clearInterval(this.transactionPageInterval);
      }
    });
  }

  previousPageFunc() {
    this.setState({
      transactionStartIndex: this.state.transactionStartIndex
        - this.state.transactionListSize > 0
        ? this.state.transactionStartIndex
          - this.state.transactionListSize
        : 0
    }, () => {
      if (this.state.transactionStartIndex === 0) {
        this.stopInterval();
      }
    });
  }

  startInterval(pageActionEnum) {
    var func;
    this.stopInterval();
    
    switch (pageActionEnum) {
      case PageActionEnum.NEXT:
        func = () => this.nextPageFunc();
        break;
      case PageActionEnum.PREVIOUS:
        func = () => this.previousPageFunc();
        break;
      default:
        throw new Error('Invalid page action value');
    }

    this.transactionPageInterval = setInterval(() => func(), 300)
  }

  stopInterval() {
    clearInterval(this.transactionPageInterval);
  }

  getTransactionsTable() {
    var trans;
    var tranArr = [];
    var transAmounts;

    var currentPage = this.state.transactionStartIndex /
        this.state.transactionListSize + 1;
      var pages = Math.ceil(this.state.transactions.length /
        this.state.transactionListSize);
    var tranPageArr = this.state.transactions
      .slice(this.state.transactionStartIndex,
        this.state.transactionStartIndex + this.state.transactionListSize);

    switch (this.state.type) {
      case TransactionTypeEnum.ALL:
        transAmounts = (
          <div className='right'>
            Deposit total: {this.state.depositAmount}
            <br/>
            Withdrawal total: {this.state.withdrawalAmount}
            <br/> 
            Net total: {this.state.transactionTotal}
          </div>
        );
        break;
      case TransactionTypeEnum.DEPOSIT:
        transAmounts = (
          <div className='right'>
            Deposit Total: {this.state.depositAmount}            
          </div>
        );
        break;
      case TransactionTypeEnum.WITHDRAWAL:
        transAmounts = (
          <div className='right'>            
            Withdrawal Total: {this.state.withdrawalAmount}
          </div>
        );
        break;
      default:
        throw new Error('Invalid transaction type');
    }

    for (var i = 0; i < tranPageArr.length; i++) {
      var transactionStyle = tranPageArr[i].amount < 0
        ? {color: 'red'}
        : {color: 'inherit'};
      var type = tranPageArr[i].type;
      //because the SQL server doesn't store the date as UTC time
      var date = tranPageArr[i].date + 'Z';
      var foreign;

      switch (type) {        
        case TransactionTypeEnum.DEPOSIT:      
          foreign = this.props.payers.get(tranPageArr[i].foreignId);
          break;
        case TransactionTypeEnum.WITHDRAWAL:          
          foreign = this.props.payees.get(tranPageArr[i].foreignId);
          break;
        case TransactionTypeEnum.TRANSFER:
          foreign = this.props.accounts.get(tranPageArr[i].foreignId);
          break;
        default:
          throw new Error('Invalid transaction type');
      }

      var category = this.props.categories
        .get(tranPageArr[i].categoryId);
      tranArr.push(
        <tr
          key={type + '#' + tranPageArr[i].id}>
          <td>{new Date(date).toLocaleDateString()}</td>
          <td>{tranPageArr[i].type}</td>
          <td
            style={transactionStyle}>
            {TransactionHelper.formatCurrency(tranPageArr[i].amount)}
          </td>
          <td>{category.name}</td>
          <td>{!!foreign ? foreign.name : 'Deleted Account'}</td>
        </tr>
      );
      }

    trans = (
      <div
        className='transTableWrapper'>
        <div
          className='tableHeader'>
          <div>
            Total transactions: {this.state.transactions.length}
          </div>
          {transAmounts}
        </div>
        <Table
          className='transTable'
          responsive
          condensed>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Category</th>
              <th>To/From</th>
            </tr>
          </thead>
          <tbody>
            {tranArr}
          </tbody>
        </Table>
        <div
          className='transactionPagination'>
          <Button
            bsStyle='success'
            disabled={this.state.transactionStartIndex <= 0}
            onClick={() => this.previousPageFunc()}
            onMouseDown={() => this.startInterval(PageActionEnum.PREVIOUS)}
            onMouseUp={() => this.stopInterval()}
            onTouchStart={() => this.startInterval(PageActionEnum.PREVIOUS)}
            onTouchEnd={() => this.stopInterval()}>
            Previous Page
          </Button>
          <div>
            Page<br/>
            {currentPage} of {pages}
          </div>
          <Button
            bsStyle='success'
            disabled={this.state.transactionStartIndex
              + this.state.transactionListSize
              >= this.state.transactions.length}
            onClick={() => this.nextPageFunc()}
            onMouseDown={() => this.startInterval(PageActionEnum.NEXT)}
            onMouseUp={() => this.stopInterval()}
            onTouchStart={() => this.startInterval(PageActionEnum.NEXT)}
            onTouchEnd={() => this.stopInterval()}>
            Next Page
          </Button>
        </div>
      </div>
    );

    return trans;
  }

  sortedValueArray(dict) {
    var arr = Object.keys(dict).map(function(key) {
      return [key, dict[key]];
    });
    return arr.sort(function(a,b) {
      return Math.abs(b[1]) - Math.abs(a[1]);
    });
  }

  getData() {
    var tempData = {"Unknown": 0};

    switch (this.state.type) {
      case TransactionTypeEnum.ALL:
        tempData = {
          'Deposit': 0,
          'Withdrawal': 0,
          'Transfer': 0
        };

        for (var i = 0; i < this.state.transactions.length; i++) {
          tempData[this.state.transactions[i].type]
            += this.state.transactions[i].amount;
        }
        break;
      case TransactionTypeEnum.DEPOSIT:
        if (!this.state.categoryId) {
          this.props.categories.forEach((value, key) => {
            tempData[value.name] = 0;
          });
          
          for (var j = 0; j < this.state.transactions.length; j++) {
            var jCategory = this.props.categories.get(this.state.transactions[j].categoryId);
            var jName = jCategory != null ? jCategory.name : "Unknown";
            tempData[jName] += this.state.transactions[j].amount;
          }
        } else {
          this.props.payers.forEach((value, key) => {
            tempData[value.name] = 0;
          });
          for (var k = 0; k < this.state.transactions.length; k++) {
            var kCategory = this.props.categories.get(this.state.transactions[k].categoryId);
            var kName = kCategory != null ? kCategory.name : "Unknown";
            tempData[kName] += this.state.transactions[k].amount;
          }
        }
        break;
      case TransactionTypeEnum.WITHDRAWAL:
        if (!this.state.categoryId) {
          this.props.categories.forEach((value, key) => {
            tempData[value.name] = 0;
          });
          for (var l = 0; l < this.state.transactions.length; l++) {
            var lCategory = this.props.categories.get(this.state.transactions[l].categoryId);
            var lName = lCategory != null ? lCategory.name : "Unknown";
            tempData[lName] += this.state.transactions[l].amount;
          }
        } else {
          this.props.payees.forEach((value, key) => {
            tempData[value.name] = 0;
          });
          for (var m = 0; m < this.state.transactions.length; m++) {
            var mCategory = this.props.categories.get(this.state.transactions[m].categoryId);
            var mName = mCategory != null ? mCategory.name : "Unknown";
            tempData[mName] += this.state.transactions[m].amount;
          }
        }
        break;
      case TransactionTypeEnum.TRANSFER:
        if (!this.state.categoryId) {
          this.props.categories.forEach((value, key) => {
            tempData[value.name] = 0;
          });
          for (var n = 0; n < this.state.transactions.length; n++) {
            var nCategory = this.props.categories.get(this.state.transactions[n].categoryId);
            var nName = nCategory != null ? nCategory.name : "Unknown";
            tempData[nName] += this.state.transactions[n].amount;
          }
        } else {
          this.props.accounts.forEach((value, key) => {
            tempData[value.name] = 0;
          });
          for (var o = 0; o < this.state.transactions.length; o++) {
            var oCategory = this.props.categories.get(this.state.transactions[o].categoryId);
            var oName = oCategory != null ? oCategory.name : "Unknown";
            tempData[oName] += this.state.transactions[o].amount;
          }
        }
         break;
      default:
        throw new Error('Invalid transaction type');
    }

    var data = this.sortedValueArray(tempData);
    var labels = [];
    var dataset = [];
    for (var z = 0; z < data.length; z++) {
      labels.push(data[z][0]);
      dataset.push(data[z][1]);
    }

    var dataObject = {
      labels: labels,
      datasets: [{
        label: 'Data values',
        data: dataset,
        backgroundColor: CP.get(labels.length),
        borderWidth: 1
      }]
    }
    return dataObject;
  }

  loadChart() {
    var ctx = $('.tranChart');
    // don't redraw on every render, only draw on initial transaction array load
    if (ctx && ctx.hasClass('noShow')) {
      ctx.removeClass('noShow');

      var data = this.getData();

      new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
          legend: {
            labels: {
              boxWidth: 10,
              padding: 5,
              filter: function(legendItem, data) {             
                return legendItem.index < 5 && data.datasets[0].data[legendItem.index] !== 0;
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.labels[tooltipItem.index]
                if (label) {
                  label += ': ';
                }
                label +=
                  TransactionHelper.formatCurrency(Number(data.datasets[tooltipItem.datasetIndex]
                    .data[tooltipItem.index]));
                return label;
              }
            }
          }
        }
      });
    }
  }

  getBody() {
    var content;

    if (this.state.transactions) {
      if (this.state.transactions.length > 0) {
        content = (
          <div>
            {this.getTransactionsTable()}
          </div>
        );
      } else {
        content = (
          <div>
            No transactions fit the criteria...
          </div>
        )
      }
    } else {
      content = (
        <div>
          Retrieving transactions per filters...
        </div>
      );
    }

    return (
      <div>
        {content}
        <canvas
          className='tranChart noShow'/>
        <hr/>
        <Button
          onClick={() => this.props.history.push('/analyze')}>
          Back
        </Button>
      </div>
    );
  }

  render () {
    return (
      <div>
        {this.getBody()}
        <Modal
          dialogComponentClass='modal'
          show={this.state.loadingTransactions}>
          <Loader color="#FFF"/>
        </Modal>
      </div>
    );
  }
}
