import * as StringUtils from './StringUtils';

export class TransactionTypeEnum {
    static values = ['All', 'Deposit', 'Withdrawal', 'Transfer'];

    static valueOf(type) {
        if (typeof type === 'string') {
            type = StringUtils.capitalize(type);
        }

        if (TransactionTypeEnum.values.includes(type)) {
            return type;
        }
        return null;
    }

    static ALL = TransactionTypeEnum.values[0];
    static DEPOSIT = TransactionTypeEnum.values[1];
    static WITHDRAWAL = TransactionTypeEnum.values[2];    
    static TRANSFER = TransactionTypeEnum.values[3];
}

export class SpecialCategoryEnum {
    static values = ['None'];

    static valueOf(type) {
        if (typeof type === 'string') {    
            type = StringUtils.capitalize(type);
        } 

        if (SpecialCategoryEnum.values.includes(type)) {
            return type;
        }
        return null;
    }

    static NONE = SpecialCategoryEnum.values[0];
}