import React, {Component} from 'react';
import {Table, Modal} from 'react-bootstrap';
import Loader from 'react-loader';
import {LinkContainer} from 'react-router-bootstrap';
import AddTransaction from './addTransaction';
import {Button, ButtonToolbar} from 'react-bootstrap';
import '../App.css';
import PageActionEnum from '../classes/PageActionEnum';
import * as TransactionHelper from '../classes/TransactionHelper';
import { API_ROUTE } from '../index';

var $ = require('jquery');

export default class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.accounts
        .get(Number(this.props.match.params.accountId)),
      transactions: null,
      loadingTransactions: false,
      transactionStartIndex: 0,
      transactionListSize: 10,
      transactionLoadLimit: 200
    };
    this.transactionPageInterval = null;
  }

  componentWillMount() {
    this.getSQLData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.accountId !== this.props.match.params.accountId) {
      this.getSQLData(nextProps);
    }
    this.setState({
      account: nextProps.accounts
        .get(Number(nextProps.match.params.accountId)),
    });
  }

  nextPageFunc() {
    this.setState({
      transactionStartIndex: this.state.transactionStartIndex
        + this.state.transactionListSize
    }, () => {
      if (this.state.transactionStartIndex + this.state.transactionListSize >= this.state.transactions.length) {
        clearInterval(this.transactionPageInterval);
      }
    });
  }

  previousPageFunc() {
    this.setState({
      transactionStartIndex: this.state.transactionStartIndex
        - this.state.transactionListSize > 0
        ? this.state.transactionStartIndex
          - this.state.transactionListSize
        : 0
    }, () => {
      if (this.state.transactionStartIndex === 0) {
        clearInterval(this.transactionPageInterval);
      }
    });
  }

  startInterval(pageActionEnum) {
    var func;
    clearInterval(this.transactionPageInterval);

    switch (pageActionEnum) {
      case PageActionEnum.NEXT:
        func = () => this.nextPageFunc();
        break;
      case PageActionEnum.PREVIOUS:
        func = () => this.previousPageFunc();
        break;
      default:
        throw new Error('Invalid page action value');
    }

    this.transactionPageInterval = setInterval(() => func(), 300)
  }

  stopInterval() {
    clearInterval(this.transactionPageInterval);
  }

  deleteAccount(e) {
    e.preventDefault();
    $.ajax({
      method: 'DELETE',
      url: API_ROUTE + '/account/'
        + this.props.match.params.accountId,
      headers: {
        x_cba_apikey: this.props.user.apiKey,
        "access-control-allow-origin": API_ROUTE,
      },
      dataType: 'json',
      cache: false,
      crossOrigin: true,
      contentType: 'application/json; charset=utf-8;'
    })
    .done((data) => {
      this.props.getAccounts();
      this.props.history.push('/');
    })
    .fail((data) => {
      console.log(data.responseText);
    });
  }

  getSQLData(props = this.props) {
    this.props.getAccounts();
    this.setState({loadingTransactions: true}, () => {
      $.ajax({
        method: 'GET',
        url: API_ROUTE + '/transaction/'
          + props.match.params.accountId + '?limit=' + this.state.transactionLoadLimit,
        headers: {
          x_cba_apikey: props.user.apiKey,
          "access-control-allow-origin": API_ROUTE,
        },
        dataType: 'json',
        cache: false,
        crossOrigin: true,
        contentType: 'application/json; charset=utf-8;'
      })
      .done((data) => {
        if (data) {
          this.setState({
            transactions: data.transactions,
            loadingTransactions: false
          });
        } else {
          this.setState({
            transactions: [],
            loadingTransactions: false
          });
        }
      })
      .fail((data) => {        
        this.setState({loadingTransactions: false});
      });
    });
  }

  getTransactionsTable() {
    var trans;
    var tranArr = [];

    if (this.state.transactions) {
      if (this.state.transactions.length > 0) {
        var currentPage = this.state.transactionStartIndex /
            this.state.transactionListSize + 1;
          var pages = Math.ceil(this.state.transactions.length /
            this.state.transactionListSize);
        var tranPageArr = this.state.transactions
          .slice(this.state.transactionStartIndex,
            this.state.transactionStartIndex + this.state.transactionListSize);
        for (var i = 0; i < tranPageArr.length; i++) {
          var transactionStyle = tranPageArr[i].amount < 0
            ? {color: 'red'}
            : {color: 'inherit'};
          var type = tranPageArr[i].type;
          //because the SQL server doesn't store the date as UTC time
          var date = tranPageArr[i].date + 'Z';
          var foreign;
          if (type === 'Withdrawal') {
            foreign = this.props.payees.get(tranPageArr[i].foreignId);
          } else if (type === 'Deposit') {
            foreign = this.props.payers.get(tranPageArr[i].foreignId);
          } else if (type === 'Transfer') {
            foreign = this.props.accounts
              .get(tranPageArr[i].foreignId);
          }
          var category = this.props.categories
            .get(tranPageArr[i].categoryId);
          var transactionLink = '/transaction/' + type +
            '/?id=' + tranPageArr[i].id +
            '&type=' + type +
            '&amount=' + tranPageArr[i].amount +
            '&date=' + date +
            '&foreignid=' + tranPageArr[i].foreignId +
            '&categoryid=' + tranPageArr[i].categoryId +
            '&accountId=' + tranPageArr[i].accountId +
            '&accountName=' + this.state.account.name;
          tranArr.push(
            <LinkContainer
              key={type + '#' + tranPageArr[i].id}
              to={transactionLink}
              params={{getSQLData: this.getSQLData.bind(this)}}>
              <tr
                id={type + '#' + tranPageArr[i].id}>
                <td>{new Date(date).toLocaleDateString()}</td>
                <td>{tranPageArr[i].type}</td>
                <td
                  style={transactionStyle}>
                  {TransactionHelper.formatCurrency(tranPageArr[i].amount)}
                </td>
                <td>{category.name}</td>
                <td>{!!foreign ? foreign.name : 'Deleted Account'}</td>
              </tr>
            </LinkContainer>
          );
        }
        trans = (
          <div
            className='transTableWrapper'>
            <Table
              className='transTable'
              hover
              responsive
              condensed>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Category</th>
                  <th>To/From</th>
                </tr>
              </thead>
              <tbody>
                {tranArr}
              </tbody>
            </Table>
            <div
              className='transactionPagination'>
              <Button
                bsStyle='success'
                disabled={this.state.transactionStartIndex <= 0}
                onClick={() => this.previousPageFunc()}
                onMouseDown={() => this.startInterval(PageActionEnum.PREVIOUS)}
                onMouseUp={() => this.stopInterval()}
                onTouchStart={() => this.startInterval(PageActionEnum.PREVIOUS)}
                onTouchEnd={() => this.stopInterval()}>
                Previous Page
              </Button>
              <div>
                Page<br/>
                {currentPage} of {pages}
              </div>
              <Button
                bsStyle='success'
                disabled={this.state.transactionStartIndex
                  + this.state.transactionListSize
                  >= this.state.transactions.length}
                onClick={() => this.nextPageFunc()}
                onMouseDown={() => this.startInterval(PageActionEnum.NEXT)}
                onMouseUp={() => this.stopInterval()}
                onTouchStart={() => this.startInterval(PageActionEnum.NEXT)}
                onTouchEnd={() => this.stopInterval()}>
                Next Page
              </Button>
            </div>
          </div>
        );
      } else {
        trans = (
          <div>
            <h4>No transactions for the account</h4>
          </div>
        );
      }
    }

    return trans;
  }

  getBody() {
    var body;

    if (!!this.state.account) {
      var lastFour;
      var balanceStyle = this.state.account.balance < 0
        ? {color: 'red'}
        : {color: 'inherit'};
      var accountLink = '/editAccount/' +
        '/?id=' + this.state.account.id +
        '&name=' + this.state.account.name +
        '&lastFour=' + this.state.account.lastFourDigits;
      if (!!this.state.account.lastFourDigits
        && this.state.account.lastFourDigits.length > 0) {
        lastFour = ' - ' + this.state.account.lastFourDigits;
      }

      body = (
        <div>
          <h1>
            Account: {this.state.account.name}{lastFour}
            <ButtonToolbar
              className='pull-left btn-block'>
              <LinkContainer
                to={accountLink}>
                <Button>
                  Edit Account
                </Button>
              </LinkContainer>
            </ButtonToolbar>
          </h1>
          <h3>
            Balance:{' '}
            <span
              style={balanceStyle}>
                {TransactionHelper.formatCurrency(this.state.account.balance)}
            </span>
          </h3>
          <AddTransaction
            account={this.state.account}
            payees={this.props.payees}
            payers={this.props.payers}
            categories={this.props.categories}
            accounts={this.props.accounts}
            getSQLData={this.getSQLData.bind(this)}
            user={this.props.user}
            payerNoneId={this.props.payerNoneId}
            payeeNoneId={this.props.payeeNoneId}
            categoryNoneId={this.props.categoryNoneId}/>
          <br/>
          <hr/>
          {this.getTransactionsTable()}
          <Modal
            dialogComponentClass='modal'
            show={this.state.loadingTransactions}>
            <Loader color="#FFF"/>
          </Modal>
        </div>
      );
    } else {
      body = (
        <div>
          <h2>Loading account information...</h2>
        </div>
      );
    }

    return body;
  }

  render() {
    return (
      this.getBody()
    );
  }
}
